import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FileHelperService } from 'src/app/services/file-helper.service';


@Component({
  selector: 'directCall-media-preview',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './media-preview.component.html',
  styleUrls: ['./media-preview.component.scss']
})
export class MediaPreviewComponent {
  constructor(
    public dialogRef: MatDialogRef<MediaPreviewComponent>,
    @Inject(MAT_DIALOG_DATA) public file: any,
    public  fileHelperService: FileHelperService
  ) {}
 
  onCloseClick(): void {
    this.dialogRef.close();
  }

}

