
<div mat-dialog-content class="mat-dialog-content">
    <div *ngIf="file" class="file-area">
        <div *ngIf="fileHelperService.isUrl(file)"  class="file-area">
            <img class="media-preview"  [src]="file">
            <!-- lo quito porque el visor de vídeo ya tiene función de ampliar, si encontrais algún navegador que no tiene está hecho. -->
            <!-- *ngIf="!fileHelperService.isURLVideo(file)" -->
            <!-- <video class="media-preview" *ngIf="fileHelperService.isURLVideo(file)" [src]="file" controls autoplay></video> -->
        </div>
        <div *ngIf="!fileHelperService.isUrl(file)"  class="file-area">
            <img class="media-preview" [src]="fileHelperService.convertFileToUrl(file)" >
            <!-- *ngIf="fileHelperService.isFileImage(file)"  -->
            <!-- <video class="media-preview" *ngIf="fileHelperService.isFileVideo(file)" [src]="fileHelperService.convertFileToUrl(file)"controls autoplay></video> -->
        </div>
    </div>
</div>
