import { ElementRef, Injectable, QueryList } from '@angular/core';


@Injectable({
  providedIn: 'root'
})

export class AlignButtonsService {

  adjustButtonDisplay(container: ElementRef, buttonElements: QueryList<ElementRef>) {
    const containerEl = container.nativeElement;
    const buttons = buttonElements.toArray();
    const buttonWidth = buttons[0].nativeElement.offsetWidth;
    const buttonsCount = buttons.length + 1; //contamos un botón más para que se muestre siempre el primero
    const totalButtonsWidth = (buttonsCount * buttonWidth) + (buttonsCount * 16); //margen

    const buttonsFitInContainer = totalButtonsWidth <= containerEl.offsetWidth;

    if (buttonsFitInContainer) {
      containerEl.style.justifyContent = 'center';
    } else {
      containerEl.style.justifyContent = 'flex-start';
    }
  }
  

}
