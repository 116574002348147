import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CallDialogData } from 'src/app/interfaces/CallDialogData';
import { ApiService } from 'src/app/services/communications/api.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';

@Component({
  selector: 'app-call-notification',
  templateUrl: './call-notification.component.html',
  styleUrls: ['./call-notification.component.css', '../../styles/components/calling-modals.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class CallNotificationComponent  {

  callerName: string = "";
  title: string = "CALL.INCOMING_CALL";

  constructor(public callNotification: MatDialogRef<CallNotificationComponent>, @Inject(MAT_DIALOG_DATA) public data: CallDialogData, private apiService: ApiService, private signalRService: SignalRService, private translate: TranslateService) {
    this.callerName = data.callerName;
    callNotification.disableClose=true;
    if (this.callerName == null) {
      this.translate.get('GUEST').subscribe((text: string) => {
        this.callerName = text;
      });
    }

    console.log("Call notification constructor caller name:" + this.callerName);
  }

  resultDialog(result: boolean) {
    console.log("Close calling componente")
    this.callNotification.close(result);
  }
}
