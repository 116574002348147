import { chatFile } from "./chatFile";

export class chatMessage {
  constructor(
    public userId: number,
    public message: string,
    public date: Date = new Date(),
    public participantName?: string,
    public translation?: string,
    public color?: string,
    public chatFile: chatFile | null = null
  ) { }
}





