import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { RoomParticipants } from 'src/app/interfaces/RoomInfo';
import { UserDataService } from 'src/app/services/userData.service';
import { SignalRService } from '../../services/communications/signalr.service';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { UserRole } from '../../constants/constants';
import { AudioConnectorService } from '../../services/audioConnector.service';
import { Subscription } from 'rxjs';
import { TranscriptionModalComponent } from './transcription-modal/transcription-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'directCall-members',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, TranslateModule, MatSlideToggleModule],
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.scss', '../../styles/components/panel.scss', '../../styles/components/select.scss']
})

export class MembersComponent {

  currentUserId;
  translateChatOn: boolean = false;
  chatEnabled: boolean = false;
  
  translateSubtitlesOn: boolean = false;
  translateSubtitleEnabled: boolean = false;
  translationVoiceEnabled: boolean = false;

  transcriptionsEnabled: boolean = false;
  transcriptionsOn: boolean = false;

  versionShow = false;

  @Input() stickyMode: boolean = false;
  @Input() withMenu: boolean = false;
  @Output() close = new EventEmitter<string>();

  updateParticipant!: Subscription;

  langs: [string, string][] = [["af", "Afrikaans"], ["sq", "Albanian"], ["ar", "Arabic"], ["hy", "Armenian"], ["az", "Azerbaijani"], ["bn", "Bangla"], ["bs", "Bosnio"], ["bg", "Bulgarian"],
    ["ca", "Catalan"], ["zh-Hans", "Chinese (Simplified)"], ["hr", "Croatian"], ["cs", "Czech"], ["da", "Danish"], ["nl", "Dutch"], ["en", "English"], ["et", "Estonian"], ["fil", "Filipino"],
    ["fi", "Finnish"], ["fr", "French"], ["de", "German"], ["el", "Greek"], ["hi", "Hindi"], ["hu", "Hungarian"], ["is", "Icelandic"], ["id", "Indonesian"], ["it", "Italian"],
    ["ja", "Japanese"], ["ko", "Korean"], ["lv", "Latvian"], ["lt", "Lithuanian"], ["ms", "Malay"], ["my", "Myanmar (Burmese)"], ["nb", "Norwegian"], ["fa", "Persian"], ["pl", "Polish"],
    ["pt", "Portuguese (Brazil)"], ["pt-pt", "Portuguese (Portugal)"], ["ro", "Romanian"], ["ru", "Russian"], ["sk", "Slovak"], ["sl", "Slovenian"],
    ["es", "Spanish"], ["sv", "Swedish"], ["th", "Thai"], ["tr", "Turkish"], ["uk", "Ukrainian"], ["cy", "Welsh"]];

  langsFrom: [string, string][] = [["af-ZA", "Afrikaans"], ["sq-AL", "Albanian"], ["ar-AE", "Arabic"], ["hy-AM", "Armenian"], ["az-AZ", "Azerbaijani"], ["bn-BD", "Bangla"], ["bs-BA", "Bosnio"], ["bg-BG", "Bulgarian"],
    ["ca-ES", "Catalan"], ["zh-CN", "Chinese (Simplified)"], ["hr-HR", "Croatian"], ["cs-CZ", "Czech"], ["da-DK", "Danish"], ["nl-NL", "Dutch"], ["en-US", "English"], ["et-EE", "Estonian"], ["fil-PH", "Filipino"],
    ["fi-FI", "Finnish"], ["fr-FR", "French"], ["de-DE", "German"], ["el-GR", "Greek"], ["hi-IN", "Hindi"], ["hu-HU", "Hungarian"], ["is-IS", "Icelandic"], ["id-ID", "Indonesian"], ["it-IT", "Italian"],
    ["ja-JP", "Japanese"], ["ko-KR", "Korean"], ["lv-LV", "Latvian"], ["lt-LT", "Lithuanian"], ["ms-MY", "Malay"], ["my-MM", "Myanmar (Burmese)"], ["nb-NO", "Norwegian"], ["fa-IR", "Persian"], ["pl-PL", "Polish"],
    ["pt-BR", "Portuguese (Brazil)"], ["pt-PT", "Portuguese (Portugal)"], ["ro-RO", "Romanian"], ["ru-RU", "Russian"], ["sk-SK", "Slovak"], ["sl-SI", "Slovenian"],
    ["es-ES", "Spanish"], ["sv-SE", "Swedish"], ["th-TH", "Thai"], ["tr-TR", "Turkish"], ["uk-UA", "Ukrainian"], ["cy-GB", "Welsh"]];

  isExpert: boolean;

  constructor(public userDataService: UserDataService, private signalRService: SignalRService, public audioConnectorService: AudioConnectorService, public dialog: MatDialog) {
    this.currentUserId = userDataService.Id!;
    this.isExpert = userDataService.isExpert();

    this.signalREventsHook();
    this.subscribeTranslationOptions();
  }

  ngOnInit(): void {
    console.log("init members component", this.audioConnectorService.translateSubtitlesOn);
    if (this.userDataService.roomInfo != null) {
      var participants = this.userDataService.roomInfo!.roomParticipants;
      var participant = participants.find(p => p.id == this.userDataService.Id);

      this.chatEnabled = participants.some(participant => participant.translationLanguage != undefined && participant.isInRoom);
      this.audioConnectorService.setTranslationVoiceEnabled(participants.some(participant => participant.audioConnectorId != undefined && participant.audioConnectorId != ''));
      this.audioConnectorService.setTranslateSubtitlesOn(false); // participants.some(participant => participant.translateSubtitleOn != false);
      this.translateChatOn = participant == undefined ? false : participant.translateChatOn;

      console.log("init members component - transcriptions: ", this.userDataService.roomInfo.transcriptionsEnabled, this.userDataService.roomInfo.transcriptionsOn);
      this.transcriptionsEnabled = this.userDataService.roomInfo.transcriptionsEnabled;
      this.transcriptionsOn = this.userDataService.roomInfo.transcriptionsOn;

      participants.forEach(p => {
        p.microphone = true;
        console.log("init participant members", p.name, p.translateVoiceOn);
      });    
    }
  }

  ngOnDestroy() {
    this.updateParticipant.unsubscribe();
  }


  subscribeTranslationOptions() {
    this.audioConnectorService.translateSubtitlesOn$.subscribe(value => {
      this.translateSubtitlesOn = value;
    });

    this.audioConnectorService.translateSubtitleEnabled$.subscribe(value => {
      this.translateSubtitleEnabled = value;
    });

    this.audioConnectorService.translationVoiceEnabled$.subscribe(value => {
      this.translationVoiceEnabled = value;
    });
  }

  closeChat() {
    this.close.emit();
  }

  translateChat() {
    this.translateChatOn = !this.translateChatOn;
    console.log('translation chat', this.translateChatOn);
    this.signalRService.setTranslateChat(this.userDataService.RoomId!, this.translateChatOn);
  }

  translateVoice(participant: RoomParticipants) {
    participant.translateVoiceOn = !participant.translateVoiceOn;
    console.log('translation voice on', this.signalRService.getConnectionId()!, participant.connectionId);
    if (this.userDataService.CallId == null) return;

    if (participant.translateVoiceOn) {
      this.audioConnectorService.startTranslation(participant);

    } else {
      console.log("STOP audioConnectorId", participant.audioConnectorId, participant.microphone);
      this.audioConnectorService.stopTranslations(participant);
    }
  }

  updateTranslationLang(participant: RoomParticipants, from: boolean) {
    var lang = participant.translationLanguage;
    if (from) lang = participant.originLanguage;
    this.signalRService.updateTranslationLang(this.userDataService.RoomId!, participant.id, lang, from);

    this.chatEnabled = this.userDataService.roomInfo!.roomParticipants.some(participant => participant.translationLanguage != undefined && participant.isInRoom);
  }

  updateTranscriptionsEnabled(value: boolean) {
    console.log("update transcriptions", value);
    this.transcriptionsEnabled = value;
  }

  toggleTranslations(participant: RoomParticipants): void {
    participant.translationsOpen = !participant.translationsOpen;
  }

  toggleTranscriptions(): void {

    if (!this.transcriptionsOn) {
      const dialogRef = this.dialog.open(TranscriptionModalComponent, {
        width: '50%'
      });

      dialogRef.afterClosed().subscribe((result: boolean) => {
        this.transcriptionsOn = result;
        this.userDataService.roomInfo.transcriptionsOn = this.transcriptionsOn;
        this.signalRService.updateTranscriptions(this.userDataService.RoomId!, this.transcriptionsOn);
      });

    } else {
      this.transcriptionsOn = !this.transcriptionsOn;
    }

    this.userDataService.roomInfo.transcriptionsOn = this.transcriptionsOn;
    this.signalRService.updateTranscriptions(this.userDataService.RoomId!, this.transcriptionsOn);
  }

  getBackgroundImage(participant: RoomParticipants): string {
    const onlineStatus = participant.isInRoom ? 'online' : 'offline';
    var userRoleLower = participant.role == UserRole.Technician ? 'technician' : 'expert'; 
    //Esto lia porque las imágenes se llaman al contrario del rol que se ve, estaban pensadas para el waitingroom del mvp. 
    //Recomiendo cambiar a participant.roleId == 0 ? 'expert' : 'technician';  y cambiar el nombre de las imágenes en cuanto
    // se ponga el waitingroom con chat
    const imageName = 'background-' + userRoleLower + '-' + onlineStatus + '.svg';
    const imagePath = 'assets/images/users/' + imageName;
    return imagePath;
  }

  signalREventsHook() {
    console.log("** signalREventsHook");
    this.updateParticipant = this.signalRService.onUpdateParticipant.subscribe(userId => {
      console.log("** Receive Update Participant");
      var participants = this.userDataService.roomInfo!.roomParticipants;
      var user: any = participants.find(roomParticipant => roomParticipant.id == userId);
      if (user == undefined) return;

      if (!user.microphone && user.translateVoiceOn) {
        console.log("TRANSLATE VOICE", user.name, user.translateVoiceOn);
        this.translateVoice(user);
      }

      console.log(this.userDataService.roomInfo!.roomParticipants);

      var count = participants.filter(p => p.connectionId != null && p.connectionId != "").length;
      console.log("count", count);
      this.audioConnectorService.setTranslationVoiceEnabled(count > 1);

      this.chatEnabled = participants.some(participant => participant.translationLanguage != undefined && participant.isInRoom);
      if (!this.chatEnabled && this.translateChatOn) this.translateChat();
      
    });

  }

  itsMe(participant: RoomParticipants): boolean {
    return (participant.id == this.currentUserId); //TODO cargar datos reales de participantes
  }

}
