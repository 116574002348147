import { ElementRef, Injectable } from '@angular/core';
import { ShowPointerResponse } from '../interfaces/Responses/ShowPointerResponse';


@Injectable({
  providedIn: 'root'
})

export class PointerService {

  constructor() {
   
  }

  //obetenemos el video que se está viendo. El publisher también tiene una etiqueta video, pero con tamaño 0.
  getNonZeroVideoElement(elRef: ElementRef): HTMLVideoElement | null {
    const videoElements = elRef.nativeElement.querySelectorAll('video');
    for (let i = 0; i < videoElements.length; i++) {
      const videoElement = videoElements[i] as HTMLVideoElement;
      const rect = videoElement.getBoundingClientRect();
      if (rect.width > 0 && rect.height > 0) {
        return videoElement;
      }
    }
    return null;
  }

  //punto de la pantalla donde se ha pulsado
  getPositionClickEvent(event: MouseEvent, rect: DOMRect) {
    const clickX = event.clientX - rect.left;
    const clickY = event.clientY - rect.top;

    return { clickX, clickY }; 
  }

  //calcula dónde se ha pulsado en la pantalla, teniendo en cuenta las bandas negras del video y si está en horizontal o vertical
  //Se envia el porcentaje para poder calcular luego el punto en la pantalla de los demás expertos en función de sus resoluciones y donde estén las bandas negras en sus videos
  getPercents(event: MouseEvent, rect: DOMRect, videoWidth: number, videoHeight: number) {
    const aspectRatio = videoWidth / videoHeight;
    let displayedWidth, displayedHeight;

    if (rect.width / rect.height > aspectRatio) {
      displayedHeight = rect.height;
      displayedWidth = displayedHeight * aspectRatio;
    } else {
      displayedWidth = rect.width;
      displayedHeight = displayedWidth / aspectRatio;
    }

    const { clickX, clickY } = this.getPositionClickEvent(event, rect);
    const offsetX = clickX - (rect.width - displayedWidth) / 2;
    const offsetY = clickY - (rect.height - displayedHeight) / 2;

    // Verificar si las coordenadas están dentro del área del video
    if (offsetX < 0 || offsetX > displayedWidth || offsetY < 0 || offsetY > displayedHeight) {
      console.log("out of limits");
      return { xPercent: null, yPercent: null };
    }

    const offsetXResult = offsetX * (videoWidth / displayedWidth);
    const offsetYResult = offsetY * (videoHeight / displayedHeight);

    const xPercent = (offsetXResult / videoWidth) * 100;
    const yPercent = (offsetYResult / videoHeight) * 100;

    return { xPercent: xPercent, yPercent: yPercent };
  }

  //calcula la posición recibida en % en función del tamaño del video, las bandas negras, si está en horizontal o vertical
  getVideoPosition(elRef: ElementRef, data: ShowPointerResponse) {
    const video = this.getNonZeroVideoElement(elRef);
    if (video == null) return { x: null, y: null };
    const rect = video.getBoundingClientRect();

    const videoSize = this.calculateVideoSize(rect.width, rect.height, video.videoWidth, video.videoHeight);

    if (!videoSize) {
      console.error('Error calculating video size.');
      return { x: null, y: null };
    }

    const { displayedWidth, displayedHeight, offsetX, offsetY } = videoSize;

    // Calcula la posición actual dentro del area del video
    const x = offsetX + (data.posX / 100) * displayedWidth;
    const y = offsetY + (data.posY / 100) * displayedHeight;

    // Verificar si las coordenadas están dentro del área del video
    if (x >= offsetX && x <= offsetX + displayedWidth && y >= offsetY && y <= offsetY + displayedHeight) {
      console.log("offset 1: ", x, y);
      return { x: x, y: y };
    }

    return { x: null, y: null };
  }

  //Calcula el tamaño del video teniendo en cuenta las bandas negras y si está en horizontal o vertical
  calculateVideoSize(containerWidth: number, containerHeight: number, videoWidth: number, videoHeight: number) {
    const videoAspectRatio = videoWidth / videoHeight;
    const containerAspectRatio = containerWidth / containerHeight;

    let displayedWidth: number;
    let displayedHeight: number;
    let offsetX: number;
    let offsetY: number;

    if (containerAspectRatio > videoAspectRatio) {
      displayedHeight = containerHeight;
      displayedWidth = displayedHeight * videoAspectRatio;
      offsetX = (containerWidth - displayedWidth) / 2;
      offsetY = 0;
    } else {
      displayedWidth = containerWidth;
      displayedHeight = displayedWidth / videoAspectRatio;
      offsetX = 0;
      offsetY = (containerHeight - displayedHeight) / 2;
    }

    return { displayedWidth, displayedHeight, offsetX, offsetY };
  }

}
