<div id="remoteCallModal_info" class="callModal" role="dialog">
  <div class="modal-body">
    <p class="incoming-call">{{this.title | translate}}</p>
    <img class="call_image call_image-back" src="/assets/images/call/remoteCallBack.svg">
    <div class="call_image-box call_image">
      <img id="remote_call_modal_image" class="call_image call_avatar" src="/assets/images/call/nophoto-contact.svg">
    </div>

    <h3 class="caller-name">{{this.callerName}}</h3>
  </div>

  <div class="modal-footer">
    <div class="dismiss-call">
      <div class="button" data-dismiss="modal" (click)="resultDialog(false)"> <i class="fa fa-phone"></i> </div>
    </div>
    <div class="open-call">
      <div class="button" data-dismiss="modal" (click)="resultDialog(true)"> <i class="fa fa-phone"></i> </div>
    </div>
  </div>
</div>
