import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ApiService } from 'src/app/services/communications/api.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { UserDataService } from 'src/app/services/userData.service';

@Component({
  selector: 'app-calling',
  templateUrl: './calling.component.html',
  styleUrls: ['./calling.component.css', '../../styles/components/calling-modals.scss'],
  standalone: true,
  imports: [TranslateModule]
})
export class CallingComponent implements AfterViewInit, OnDestroy {

  secondsCounter: number;
  timeInterval: any;
  timerElement: HTMLElement | null = null;
  messageText: string = "CALL.CALLING"

  constructor(public dialogRef: MatDialogRef<CallingComponent>, private apiService: ApiService, private userService: UserDataService, private signalRService: SignalRService, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.secondsCounter = data.timeout;
    this.dialogRef.disableClose = true;
  }
  
  ngOnDestroy(): void {
    console.log("Calling component destroy");
    clearInterval(this.timeInterval);
    this.timeInterval = null;
  }

  ngAfterViewInit(): void {
    this.timerElement = document.getElementById("timerElement");
    this.timerElement!.innerText = this.secondsCounter.toString();
    this.timeInterval = setInterval(() => this.updateTime(), 1000);
    this.sendCallNotification(true);
  }

  updateTime() {
    this.secondsCounter = this.secondsCounter - 1;
    this.timerElement!.innerHTML = this.secondsCounter.toString();
    if (this.secondsCounter <= 0) {
      console.log("Timer call timeout");
      this.endDialog(false);
    }
  }

  endDialog(goToCall: boolean) {
    console.log("EndDialog");
    clearInterval(this.timeInterval);
    this.dialogRef.close(goToCall);
    this.sendCallNotification(goToCall);
  }

  sendCallNotification(call: boolean) {
    console.log("Send call notification value: " + call);
    this.apiService.callNotification(this.userService.Id!, this.userService.RoomId!, this.signalRService.getConnectionId()!, call).subscribe({
      next: () => {
        console.log("Call notification sent");
      },
      error: err => {
        console.log("Call notification sending error :" + JSON.stringify(err));
      }
    });
  }

}
