import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LinkifyPipe } from './linkify.pipe';
import { SanitizePipe } from './sanitize.pipe';
import { EscapeHtmlPipe } from './escape-html.pipe';

@NgModule({
  declarations: [LinkifyPipe, SanitizePipe, EscapeHtmlPipe],
  imports: [CommonModule],
  exports: [LinkifyPipe, SanitizePipe, EscapeHtmlPipe]
})
export class PipesModule { }
