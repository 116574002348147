import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { MediaType } from '../constants/constants';

@Injectable({
  providedIn: 'root'
})

export class FileHelperService {
  private static readonly imageFilesExtensions: string[] = ["jpg", "png", "jpeg", "gif"];
  private static readonly documentFilesExtensions: string[] = ["pdf"];
  private static readonly videoFilesExtensions: string[] = ["mp4", "mov", "mpeg", "avi", "3gpp"];
  private static readonly audioFilesExtensions: string[] = ["wav"];



  constructor(private sanitizer: DomSanitizer) {
  }

  isURLVideo(fileUrl: string): boolean {
    //Esto habrá que cambiarlo cuando las imágenes vengan de servidor porque seguramente no tengan extensión
    return fileUrl.toLowerCase().endsWith('.mp4') ||
      fileUrl.toLowerCase().endsWith('.avi') ||
      fileUrl.toLowerCase().endsWith('.mov');
  }

  isFileVideo(file: File): boolean {
    if (file.type.startsWith('video/')) {
      console.log('el archivo es de tipo video', file.name);
    }
    return file.type.startsWith('video/');
  }

  isFileImage(file: File): boolean {
    if (file.type.startsWith('image/')) {
      console.log('el archivo es de tipo imagen', file.name);
    }
    return file.type.startsWith('image/');
  }

  isFileAudio(file:File): boolean {
    if (file.type.startsWith('audio/')) {
      console.log('el archivo es de tipo audio', file.name);
    }
    return file.type.startsWith('audio/');
  }

  convertFileToUrl(file: File): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  isUrl(fileOrUrl: any): boolean {
    return typeof fileOrUrl === 'string';
  }

  getFileType(fileName: string): MediaType {
    // Get the file extension in lowercase for case-insensitive comparison
    const fileExtension = this.getFileExtension(fileName).toLowerCase();

    // Use a switch statement with type guards for more control and type safety
    switch (true) {
      case FileHelperService.imageFilesExtensions.includes(fileExtension):
        return MediaType.Image;
      case FileHelperService.documentFilesExtensions.includes(fileExtension):
        return MediaType.Document;
      case FileHelperService.videoFilesExtensions.includes(fileExtension):
        return MediaType.Video;
      case FileHelperService.audioFilesExtensions.includes(fileExtension):
        return MediaType.Audio;
      default:
        return MediaType.Unknown;
    }
  }

  getFileExtension(filename: string): string {
    // Use a regular expression to match the file extension
    const match = filename.match(/\.(.+)$/);
    // If a match is found, return the captured extension
    if (match) {
      return match[1];
    }
    // If no match is found, return an empty string
    return '';
  }

  blobToFile = (blob: Blob, fileName:string): File => {
    const b: any = blob;

    b.lastModifiedDate = new Date();
    b.name = fileName;
    b.filename = fileName;
 
    return new File([b],'captured.png',{ type : 'image/png'  })
  }
}