import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message/message.component';
import { chatMessage } from '../../models/chatMessage'
import { FormsModule } from '@angular/forms';
import { UserDataService } from 'src/app/services/userData.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'directCall-chat',
  standalone: true,
  imports: [CommonModule, MessageComponent, FormsModule],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss', '../../styles/components/panel.scss']
})

export class ChatComponent implements AfterViewInit, OnDestroy {

  @Input() stickyMode: boolean = false;
  @Output() close = new EventEmitter<string>();

  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;

  public firstLoad: boolean = true;
  private mutationObserver!: MutationObserver;

  newMessage: chatMessage;
  group: chatMessage[][] = [];
  currentName;

  allowMembers = false;
  allowFiles = false;

  chatMessageReceivedSubscription: Subscription;


  constructor(private userDataService: UserDataService, private signalService: SignalRService, private apiService: ApiService, private chatService: ChatService) {

    this.currentName = userDataService.Name != '' ? userDataService.Name! : 'GUEST';

    this.newMessage = new chatMessage(this.userDataService.Id!, '');
    this.newMessage.participantName = this.currentName;
    console.debug("Chat component constructor...");

    this.chatMessageReceivedSubscription = this.signalService.onChatMessageReceived.subscribe((data => {
      console.log("onChatMessage...");
      this.addMessage(data);
    }));

    // Ahora no se usa, la sala es siempre nueva
    this.chatService.onChatReceived.subscribe(() => {
      console.log("Chat emiter received:" + JSON.stringify(this.chatService.messages));
      this.firstLoad = true;
      this.group = this.groupMessagesByDate(this.chatService.messages);
    })
  }

  ngOnDestroy(): void {
    console.debug("Chat received destroy subscription");
    this.chatMessageReceivedSubscription.unsubscribe();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  ngAfterViewInit(): void {
    console.log("After view checked:" + JSON.stringify(this.chatService.messages))
    this.group = this.groupMessagesByDate(this.chatService.messages);
    this.initializeMutationObserver();
  }

  initializeMutationObserver(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.placeScrollDown();
      this.firstLoad = false;
    });

    this.mutationObserver.observe(this.messagesContainer.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });
  }

  private placeScrollDown(): void {
    if (this.messagesContainer && this.messagesContainer.nativeElement) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
      } catch (err) { }
    }
    
  }

  groupMessagesByDate(messages: chatMessage[]): chatMessage[][] {
    const groups: { [key: string]: chatMessage[] } = {};

    messages.forEach(message => {
      //también les asigno el color (preparado para cuando sean más de dos)
      message.color = this.userDataService.roomInfo.roomParticipants.find(p => p.id == message.userId)?.color;

      const dateKey = new Date(message.date).toDateString();
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(message);
    });

    return Object.values(groups);
  }

  fixHeight() {
    const textarea = document.getElementById('chatInput') as HTMLTextAreaElement;
    textarea.style.height = 'auto';
    const alturaDeseada = textarea.scrollHeight - 32;
    const alturaMaxima = 6 * 22; // Altura máxima de 6 líneas
    textarea.style.height = Math.min(alturaDeseada, alturaMaxima) + 'px';
  }

  addNewMessage(): void {
    console.log("Message : " + this.newMessage.message);
    if (this.newMessage.userId && (this.newMessage.message.trim() || this.newMessage.file)) {
      var user = this.userDataService.roomInfo.roomParticipants.find(p => p.id == this.userDataService.Id!);
      if (user == undefined) return;

      this.newMessage.date = new Date();
      this.newMessage.color = user.color;
      if (!user.translateChatOn) this.chatService.messages.push(this.newMessage);

      this.newMessage = new chatMessage(this.userDataService.Id!, this.newMessage.message, this.newMessage.date);
      this.newMessage.participantName = this.userDataService.Name!;

      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }

      this.group = this.groupMessagesByDate(this.chatService.messages);

      console.log("Message : ", user.translateChatOn, user.translationLanguage);

      this.apiService.SendChatMessage(this.signalService.getConnectionId()!, this.userDataService.RoomId!, this.newMessage, user.translateChatOn, user.translationLanguage).subscribe({
        next: data => {
          console.log("Send Chat message ok");
        },
        error: error => {
          console.log("Send chat message error:" + error);
        }

      });

      this.newMessage.message = "";
    } else console.log('No había participante, o mensaje o imagen');

  }

  onFileSelected(event: any) {
    const file = event.target.files[0];
    this.newMessage.file = file;
  }

  clearAttachment() {
    this.newMessage.file = '';
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  addMessage(data: chatMessage) {
    console.info("chat message added");
    this.chatService.messages.push(data);
    this.group = this.groupMessagesByDate(this.chatService.messages);
  }

  closeChat() {
    this.close.emit();
  }

}

