<div class="message" [class.me]="chatMessage.userId == currentUserId">
    <div *ngIf="chatMessage.participantName" class="participant" [style.color]="color"><p>{{chatMessage.participantName | translate}}</p></div>
    <div *ngIf="!chatMessage.participantName" class="participant" [style.color]="color"><p>{{'GUEST' | translate}}</p></div>
    <!-- Se podrá quitar el if y la segunda línea cuando todos tengan nombre -->
    <div class="globe" [style.background]="backgroundColor" [style.border]="borderColor">

          <div *ngIf="chatMessage.file">
            <div *ngIf="fileHelperService.isUrl(chatMessage.file)">
                <img *ngIf="!fileHelperService.isURLVideo(chatMessage.file)" [src]="chatMessage.file" (click)="openMediaModal(chatMessage.file)">
                <video *ngIf="fileHelperService.isURLVideo(chatMessage.file)" [src]="chatMessage.file" controls></video>
                <!-- (click)="openMediaModal(chatMessage.file)" --> 
            </div>
            <div *ngIf="!fileHelperService.isUrl(chatMessage.file)">
                <img *ngIf="fileHelperService.isFileImage(chatMessage.file)" [src]="fileHelperService.convertFileToUrl(chatMessage.file)" (click)="openMediaModal(chatMessage.file)">
                <video *ngIf="fileHelperService.isFileVideo(chatMessage.file)" [src]="fileHelperService.convertFileToUrl(chatMessage.file)" controls></video>
                <!-- (click)="openMediaModal(chatMessage.file)" -->
            </div>
        </div>
        <div class="text">
            <p *ngIf="chatMessage.message" [innerHTML]="chatMessage.message | linkify | escapeHtml"></p>
        </div>
        <div class="text" *ngIf="chatMessage.message && chatMessage.translation">
          <p class="translate"><i [innerHTML]="chatMessage.translation | linkify | escapeHtml"></i></p>
        </div>
        <div class="date"><p> {{ chatMessage.date | date:'hh:mm' }}</p></div>
    </div>
</div>
