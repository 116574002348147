import { Component, HostListener, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { WhitelabelService } from './services/whitelabel.service';
import { ConfigService } from './services/config.service';
import { Router } from '@angular/router';
import { SecurityService } from './services/security.service';
import { SignalRService } from './services/communications/signalr.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title: string | undefined = 'Direct call';

  constructor(private translate: TranslateService, private whitelabelService: WhitelabelService, private configurationService: ConfigService, private signalRService: SignalRService, private router: Router, private securityService: SecurityService) {

    this.translate.addLangs(['es', 'en']);
  }

  ngOnInit() {
    this.whitelabelService.initService();
    // Suscribirse a los cambios en el servicio WhitelabelService
    this.whitelabelService.currentWhitelabelObservable.subscribe((whitelabel) => {
      if (whitelabel != '') {
        this.title = this.whitelabelService.getCurrentAppName();
      }
    });

    if (!this.translate.langs.includes(this.configurationService.language)) {
      this.configurationService.changeLanguage('en');
    }

    this.translate.use(this.configurationService.language);
    this.translate.setDefaultLang(this.configurationService.language);
  }

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any): void {
    console.log("Event: ", $event)
    const confirmationMessage = this.translate.get('LEAVE_MESSAGE');
    $event.returnValue = confirmationMessage;
  }

  @HostListener('window:load', ['$event'])

  loadNotification($event: any): void {
    console.log("Event: ", $event);
    this.signalRService.unRegisterEvents();
    let token = this.securityService.getUrlToken();
    if (token === null)
      this.router.navigate(['']);
    else {
      this.router.navigate(
        [''],
        { queryParams: { token: token } }
      );

    }
  }


}
