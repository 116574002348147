import { EventEmitter, Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { ApiUrls, Result_Ko, Result_Ok, UserRole } from '../../constants/constants';
import { environment } from 'src/environment/environment';
import * as signalRConstants from '../../constants/signalrMessages';
import { Observable } from 'rxjs';
import { UserDataService } from '../userData.service';
import { RoomParticipants } from '../../interfaces/RoomInfo';
import { ImageReceived } from '../../interfaces/imageReceived';
import { ModifiedObjectsEmitterData } from 'src/app/interfaces/SignalRDrawObject';
import { chatMessage } from 'src/app/models/chatMessage';
import { StartAudioConnectorResponse } from '../../interfaces/Responses/AudioConnectorResponse';
import { CallNotificationInfo, CallNotificationResponse } from 'src/app/interfaces/call-notifications';
import { ShowPointerResponse } from '../../interfaces/Responses/ShowPointerResponse';


@Injectable({
  providedIn: 'root'
})

export class SignalRService {

  private hubConnection: signalR.HubConnection;

  onRoomDeleted = new EventEmitter<string>();
  onUpdateParticipant = new EventEmitter<any>();  
  onImageReceived = new EventEmitter<ImageReceived>();
  onCanvasObjectReceived = new EventEmitter<ModifiedObjectsEmitterData>();
  OnLiveDrawingClose = new EventEmitter<void>();
  onRemoteRecording = new EventEmitter();
  onHDImageRequest = new EventEmitter<string>();
  onChatMessageReceived = new EventEmitter<chatMessage>();
  onPublishTranslatedAudio = new EventEmitter<string>();
  onStartAudioConnector = new EventEmitter<StartAudioConnectorResponse>();
  onTranscriptionReady = new EventEmitter<string>();
  onSubtitleReady = new EventEmitter<string>();
  onCallNotification = new EventEmitter<CallNotificationInfo>();
  onCallNotificationResponse = new EventEmitter<CallNotificationResponse>();
  onReceivePointer = new EventEmitter<ShowPointerResponse>();

  constructor(private userDataService: UserDataService) {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.apiUrl + ApiUrls.signalRHub) //,{skipNegotiation : false  , transport : signalR.HttpTransportType.WebSockets})
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect()
      .build();
  }

  public startSignalR(): Observable<string> {

    return new Observable<string>(observer => {
      this.hubConnection
        .start()
        .then(() => {
          console.info('SignalR Connection started: ' + this.hubConnection?.connectionId);
          this.registerEvents();

          observer.next(Result_Ok);
          observer.complete();
        })
        .catch(err => {
          console.error('Error while starting connection: ' + err);
          observer.next(Result_Ko);
          observer.complete();
        });
    });

  }

  sendSignalRConnectionData(userId: Number, roomId: number) {
    console.log("Sending sr connection data");
    this.hubConnection?.invoke(signalRConstants.ConnectionData, userId, roomId)
      .then(response => {
        console.log("Response to : " + signalRConstants.ConnectionData + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.ConnectionData + " => " + error);
      });

  }

  liveDrawingClose(roomId: number, userId: number) {
    console.log("Sending live drawing close connection data");
    this.hubConnection?.invoke(signalRConstants.LiveDrawingClose, userId, roomId)
      .then(response => {
        console.log("Response to : " + signalRConstants.LiveDrawingClose + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.LiveDrawingClose + " => " + error);
      });
  }

  setTranslateSubtitles(roomId: number, value: boolean) {
    console.log("Sending translation subtitles ", value);
    this.hubConnection?.invoke(signalRConstants.setTranslationSubtitles, roomId, value)
      .then(response => {
        console.log("Response to : " + signalRConstants.setTranslationSubtitles + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.setTranslationSubtitles + " => " + error);
      });
  }

  setTranslateChat(roomId: number, value: boolean) {
    console.log("Sending enable chat", value);
    this.hubConnection?.invoke(signalRConstants.setChatTranslation, roomId, value)
      .then(response => {
        console.log("Response to : " + signalRConstants.setChatTranslation + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.setChatTranslation + " => " + error);
      });
  }

  updateTranslationLang(roomId: number, userId: number, lang: string, from: boolean) {
    console.log("Update lang", userId, lang, from);
    this.hubConnection?.invoke(signalRConstants.updateTranslationLang, roomId, userId, lang, from)
      .then(response => {
        console.log("Response to : " + signalRConstants.updateTranslationLang + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.updateTranslationLang + " => " + error);
      });
  }

  updateMicrophone(roomId: number, userId: number, active: boolean) {
    console.log("Update microphone", userId, active);
    this.hubConnection?.invoke(signalRConstants.updateMicrophone, roomId, userId, active)
      .then(response => {
        console.log("Response to : " + signalRConstants.updateMicrophone + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.updateMicrophone + " => " + error);
      });
  }


  updateTranscriptions(roomId: number, value: boolean) {
    console.log("Sending transcriptions", value);
    this.hubConnection?.invoke(signalRConstants.updateTranscriptions, roomId, value)
      .then(response => {
        console.log("Response to : " + signalRConstants.updateTranscriptions + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.updateTranscriptions + " => " + error);
      });
  }

  sendPointer(roomId: number, userId: number, posX: number, posY: number) {
    this.hubConnection?.invoke(signalRConstants.sendPointer, roomId, userId, posX, posY)
      .then(response => {
        console.log("Response to : " + signalRConstants.sendPointer + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.sendPointer + " => " + error);
      });
  }

  startTranscription(sessionId: string, callId: number, streamId: string, userConnectionId: string ) {
    return this.hubConnection?.invoke(signalRConstants.startTranscription, sessionId, callId, streamId, userConnectionId);
  }

  startTranslation(sessionId: string, callId: number, lang: string, streamId: string, userConnectionId: string, langFrom: string) {
    return this.hubConnection?.invoke(signalRConstants.startTranslation, sessionId, callId, streamId, userConnectionId, lang, langFrom);
  }

  stopTranslations(sessionId: string, callId: number, audioConnectorStreamId: string, userConnectionId: string) {
    return this.hubConnection?.invoke(signalRConstants.stopTranslation, sessionId, callId, audioConnectorStreamId, userConnectionId);
  }

  stopAudioConnector(sessionId: string, audioConnectorStreamId: string, userConnectionId: string) {
    return this.hubConnection?.invoke(signalRConstants.stopAudioConnector, sessionId, audioConnectorStreamId, userConnectionId);
  }

  registerEvents() {
    this.hubConnection.on(signalRConstants.RoomDeleted, data => this.onRoomDeleted.emit(data));
    this.hubConnection.on(signalRConstants.ImageReceived, data => this.ImageReceived(data));
    this.hubConnection.on(signalRConstants.CanvasObject, data => this.CanvasObjectReceived(data));
    this.hubConnection.on(signalRConstants.LiveDrawingClose, data => this.LiveDrawingCloseReceived(data));
    this.hubConnection.on(signalRConstants.RemoteRecording, data => this.RemoteRecording(data));
    this.hubConnection.on(signalRConstants.requestHDImage, data => this.requestedHDImage(data));
    this.hubConnection.on(signalRConstants.sendChatMessage, data => this.chatMessageReceived(data));
    this.hubConnection.on(signalRConstants.setOpenTokConnectionId, data => this.setOpenTokConnectionId(data));
    this.hubConnection.on(signalRConstants.audioTranslationReady, data => this.audioTranslationReady(data));
    this.hubConnection.on(signalRConstants.audioTranscriptionReady, data => this.audioTranscriptionReady(data));
    this.hubConnection.on(signalRConstants.audioSubtitleReady, data => this.audioSubtitleReady(data));
    this.hubConnection.on(signalRConstants.setTranslationSubtitles, data => this.setTranslationSubtitles(data));
    this.hubConnection.on(signalRConstants.setChatTranslation, data => this.setChatTranslation(data));
    this.hubConnection.on(signalRConstants.setTranslationLanguages, data => this.setTranslationLanguages(data));
    this.hubConnection.on(signalRConstants.CallNotification, data => this.callNotification(data));
    this.hubConnection.on(signalRConstants.CallNotificationResponse, data => this.callNotificationResponse(data));
    this.hubConnection.on(signalRConstants.showPointer, data => this.showPointer(data));
    this.hubConnection.on(signalRConstants.addParticipant, data => this.addParticipantReceived(data));
    this.hubConnection.on(signalRConstants.disconnectParticipant, data => this.disconnectParticipantReceived(data));
    this.hubConnection.on(signalRConstants.setMicrophone, data => this.setMicrophone(data));
    this.hubConnection.on(signalRConstants.setTranscriptions, data => this.setTranscriptions(data));
    this.hubConnection.on(signalRConstants.setConnectionId, data => this.setConnectionId(data));
  }

 public unRegisterEvents() {
  console.log("SignalR unregister events");
    this.hubConnection.off(signalRConstants.RoomDeleted);
    this.hubConnection.off(signalRConstants.ImageReceived);
    this.hubConnection.off(signalRConstants.CanvasObject);
    this.hubConnection.off(signalRConstants.LiveDrawingClose);
    this.hubConnection.off(signalRConstants.RemoteRecording);
    this.hubConnection.off(signalRConstants.requestHDImage);
    this.hubConnection.off(signalRConstants.sendChatMessage);
    this.hubConnection.off(signalRConstants.setOpenTokConnectionId);
    this.hubConnection.off(signalRConstants.audioTranslationReady);
    this.hubConnection.off(signalRConstants.audioTranscriptionReady);
    this.hubConnection.off(signalRConstants.audioSubtitleReady);
    this.hubConnection.off(signalRConstants.setTranslationSubtitles);
    this.hubConnection.off(signalRConstants.setChatTranslation);
    this.hubConnection.off(signalRConstants.setTranslationLanguages);
    this.hubConnection.off(signalRConstants.CallNotification);
    this.hubConnection.off(signalRConstants.CallNotificationResponse);
    this.hubConnection.off(signalRConstants.showPointer);
    this.hubConnection.off(signalRConstants.addParticipant);
    this.hubConnection.off(signalRConstants.disconnectParticipant);
    this.hubConnection.off(signalRConstants.setMicrophone);
    this.hubConnection.off(signalRConstants.setConnectionId);
  }


  ImageReceived(data: any): any {
    console.log("Image received , is live: " + data.isLiveImage);
    let imageReceived: ImageReceived = { base64Image: data.base64Image, isLiveDrawing: data.isLiveImage, isHdImage: data.isHdImage };
    this.onImageReceived.emit(imageReceived);
  }

  addParticipantReceived(data: RoomParticipants): any {
    var index = this.userDataService.roomInfo!.roomParticipants.findIndex(p => p.id == data.id);
    console.log("****addParticipantReceived : ", data);
    if (index == -1) this.userDataService.roomInfo!.roomParticipants.push(data);
    else this.userDataService.roomInfo!.roomParticipants[index] = data;
    this.onUpdateParticipant.emit(data.id);
  }

  disconnectParticipantReceived(data: RoomParticipants): any {
    var index = this.userDataService.roomInfo!.roomParticipants.findIndex(p => p.id == data.id);
    console.log("****disconnectParticipantReceived : ", data);
    if (index == -1) return;

    this.userDataService.roomInfo!.roomParticipants[index] = data;
    this.onUpdateParticipant.emit(data.id);
  }

  getConnectionId(): string | null {
    return this.hubConnection.connectionId;
  }

  SendCanvasObject(userId: number, roomId: number, object: ModifiedObjectsEmitterData) {
    console.log("Sending CanvasObject connection data");
    this.hubConnection?.invoke(signalRConstants.CanvasObject, userId, roomId, object)
      .then(response => {
        console.log("Response to : " + signalRConstants.CanvasObject + " => " + response);
      })
      .catch(error => {
        console.log("Error on  : " + signalRConstants.CanvasObject + " => " + error);
      });
  }

  CanvasObjectReceived(data: ModifiedObjectsEmitterData): any {
    this.onCanvasObjectReceived.emit(data);
  }

  LiveDrawingCloseReceived(data: any): any {
    this.OnLiveDrawingClose.emit();
  }

  RemoteRecording(data: any): any {
    this.onRemoteRecording.emit(data);
  }

  requestedHDImage(senderSignalRId: string) {
    console.log("HD image requested");
    this.onHDImageRequest.emit(senderSignalRId);
  }

  chatMessageReceived(data: chatMessage) {
    console.log("Chat message received: " + JSON.stringify(data));
    this.onChatMessageReceived.emit(data);
  }

  setOpenTokConnectionId(data: StartAudioConnectorResponse) {
    this.onStartAudioConnector.emit(data);
  }

  audioTranslationReady(data: string) {
    this.onPublishTranslatedAudio.emit(data);
  }

  audioTranscriptionReady(data: string) {
    this.onTranscriptionReady.emit(data);
  }

  audioSubtitleReady(data: string) {
    this.onSubtitleReady.emit(data);
  }

  setTranslationSubtitles(data: any): any {
    console.log("setTranslationSubtitles: ", data);
    this.userDataService.roomInfo!.roomParticipants.forEach(participant => participant.translateSubtitleOn = data);
  }

  setChatTranslation(data: any): any {
    console.log("setTranslateChat: ", data);
    this.userDataService.roomInfo!.roomParticipants.forEach(participant => participant.translateChatOn = data);
  }

  setTranscriptions(data: any): any {
    console.log("updateTranscriptions: ", data);
    this.userDataService.roomInfo!.transcriptionsOn = data;
  }

  setTranslationLanguages(data: any): any {
    console.log("setTranslationLanguages: ", data);
    
    var user = this.userDataService.roomInfo!.roomParticipants.find(roomParticipant => roomParticipant.id == data.userId);
    if (user == undefined) return;

    user.originLanguage = data.fromLanguage;
    user.translationLanguage = data.toLanguage;

    this.onUpdateParticipant.emit(data.userId);
  }

  callNotification(data: CallNotificationInfo) {
    console.log("Incoming call notification", JSON.stringify(data));
    this.onCallNotification.emit(data);
  }

  callNotificationResponse(result: any): any {
    console.log("Incoming call notification Response", result);
    this.onCallNotificationResponse.emit(result);
  }

  showPointer(data: ShowPointerResponse) {
    console.log("**ShowPointer event back", data);
    this.onReceivePointer.emit(data);
  }

  setMicrophone(data: any) {
    console.log("setMicrophone: ", data);

    var user = this.userDataService.roomInfo!.roomParticipants.find(roomParticipant => roomParticipant.id == data.userId);
    if (user == undefined) return;

    user.microphone = data.value;

    this.onUpdateParticipant.emit(data.userId);
  }

  setConnectionId(data: any) {
    console.log("setConnectionId: ", data);

    var user = this.userDataService.roomInfo!.roomParticipants.find(roomParticipant => roomParticipant.id == data.userId);
    if (user == undefined) return;

    user.connectionId = data.value;

    this.onUpdateParticipant.emit(data.userId);
  }
}


