import { Injectable } from '@angular/core';
import { AuthTokenResponse } from '../interfaces/Responses/AuthTokenResponse';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from './config.service';
import { EncryptStorage } from 'encrypt-storage';
import { environment } from 'src/environment/environment';

@Injectable({
  providedIn: 'root'
})
export class SecurityService implements HttpInterceptor {
  token: string | null = null;
  tokenType: string | null = null;
  encryptStorage = new EncryptStorage('secret-key-value', { storageType: 'localStorage', });
  constructor(private configurationService: ConfigService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let request = req;
    request = req.clone({
      setHeaders: {
        'language': this.configurationService.language
      }
    });


    if (this.token && this.tokenType) {
      request = req.clone({
        setHeaders: {
          authorization: `${this.tokenType} ${this.token}`,
          'Content-Type': 'application/json; charset=utf-8',
        }
      });
    }

    return next.handle(request);
  }

  public addSecurityToken(authTokenResponse: AuthTokenResponse) {
    this.token = authTokenResponse.token;
    this.tokenType = authTokenResponse.authType;
  }

  public getUrlToken(): string | null {
    let encrypted = sessionStorage.getItem(environment.tokenName)
    let token = this.encryptStorage.decryptValue(encrypted!);
    return token;
  }

  public saveUrlToken(token: string) {
    let encrypted = this.encryptStorage.encryptValue(token);
    sessionStorage.setItem(environment.tokenName, encrypted);
  }
}
