import { AfterViewInit, Component, ElementRef, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ChatComponent } from '../chat/chat.component';
import { MembersComponent } from '../members/members.component';
import { UserDataService } from 'src/app/services/userData.service';
import *  as OT from '@opentok/client';
import * as constants from 'src/app/constants/constants';
import { ApiService } from 'src/app/services/communications/api.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { PointerService } from 'src/app/services/pointer.service';
import { RoomInfo } from 'src/app/interfaces/RoomInfo';
import { TechnicianStream } from 'src/app/constants/OpenTokConstants';
import { ImageEditorComponent } from '../image-editor/image-editor.component';
import { MatDialog } from '@angular/material/dialog';
import { ImageEditorResultData } from 'src/app/interfaces/imageeditor-resultdata';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { NotificationSnackComponent } from '../notificationSnak/notificationSnack.component';
import { TagsModalComponent } from './tags-modal/tags-modal.component';
import { ImageReceived } from 'src/app/interfaces/imageReceived';
import { ImageReceivedEditorComponent } from '../ImageReceivedEditor/ImageReceivedEditor.component';
import { Subscription } from 'rxjs';
import { PublisherComponent } from '../publisher/publisher.component';
import { SubscriberComponent } from '../subscriber/subscriber.component';
import { ChatService } from 'src/app/services/chat.service';
import { PointerComponent } from './pointer/pointer.component';
import { AlignButtonsService } from '../../services/buttons.service';
import { AudioConnectorService } from '../../services/audioConnector.service';


@Component({
  selector: 'app-call',
  standalone: true,
  templateUrl: './call.component.html',
  styleUrls: ['./call.component.scss'],
  imports: [CommonModule, TranslateModule, ChatComponent, MembersComponent, MatSnackBarModule, TagsModalComponent, PublisherComponent, SubscriberComponent, PointerComponent]
})

export class CallComponent implements AfterViewInit {

  @ViewChildren(PublisherComponent) publishers!: QueryList<PublisherComponent>;
  @ViewChildren(SubscriberComponent) subscribers!: QueryList<SubscriberComponent>;
  @ViewChild(MembersComponent) members!: MembersComponent;

  @ViewChild('container') container!: ElementRef;
  @ViewChild('videoContainer') videoContainer!: ElementRef;
  @ViewChildren('button') buttonElements!: QueryList<ElementRef>;

  roomInfo!: RoomInfo;
  isExpert: boolean;
  opacity = 0;
  microActive = true;
  flashOn = false;
  screenShareOn = false;
  screenShareDisabled = false;
  screenShareAvailable = true;
  startTime: Date | null = null;
  recording = false;
  videoId: number | undefined;
  recordingIntervalId: any;
  intervalRecording: any;
  fullScreen = false;
  chatOn = false;
  membersOn = false;
  resolution = 720;
  subtitle: string = "";
  translateVoiceEnabled = false;

  //Botones y extensiones ocultas
  hasFlash = false;
  hasResolution = false;

  descriptionText: string = 'The call will start when the technician connects';

  publisherType = constants.PublisherType; //para el html
  navBars = constants.NavBars; //para el html
  currentnavBar = constants.NavBars.BASIC;

  session!: OT.Session;
  streams: Array<OT.Stream> = [];

  imageReceivedSubscribtion!: Subscription;
  roomDeletedSubscription!: Subscription;
  remoteSubscribtion!: Subscription;
  HDImageSubscription!: Subscription;
  audioTranslatedReady!: Subscription;
  startAudioConnector!: Subscription;
  stopAudioConnector!: Subscription;
  transcriptionReady!: Subscription;
  subtitleReady!: Subscription;
  receivePointer!: Subscription;
  chatMessageReceivedSubscription: Subscription;

  videoRect: DOMRect | null = null;

  constructor(private router: Router, private userDataService: UserDataService, private translateService: TranslateService, private apiService: ApiService,
    private signalRService: SignalRService, private snackBar: MatSnackBar, public dialog: MatDialog, private chatService: ChatService, private pointerService: PointerService,
    private elRef: ElementRef, private buttonsService: AlignButtonsService, private audioConnectorService: AudioConnectorService) {

    this.isExpert = this.userDataService.isExpert();
    this.roomInfo = this.userDataService.roomInfo;

    console.log('User role of ' + this.userDataService.Name + "(" + this.userDataService.Id + ") --> " + this.userDataService.Role);
    this.signalREventsHook();
    if (!this.roomInfo?.tags && this.roomInfo) this.roomInfo.tags = [];
    this.chatMessageReceivedSubscription = this.signalRService.onChatMessageReceived.subscribe((data => {
      if (!this.chatOn) {
        this.openSnackBar(data.chatData.message);
      }
    }));
  }

  ngOnInit(): void {
    this.session = OT.initSession(this.userDataService.otApiKey!, this.userDataService.otSession!);

    console.log("after init session. Register events. SessionId:", this.session.sessionId);

    this.session.connect(this.userDataService.otCallToken!, err => {
      if (err) {
        console.log("conect error: ", err);

      } else {

        var videoPublisher = this.publishers.find(publisher => publisher.publisherType == constants.PublisherType.Video);
        videoPublisher?.createVideoPublisher();

        this.members.updateTranscriptionsEnabled(false);

        console.log("After connect", this.session.sessionId);
        this.registerConnectionId(this.session.connection?.connectionId, this.userDataService?.RoomId!, this.userDataService.Id);

      }
    });

    this.session.on("streamCreated", event => {
      console.log("STREAM CREATED", event.stream.name, this.streams.length);
      this.streams.push(event.stream);

      if (event.stream.videoType == constants.StreamVideoType.Screen) this.screenShareDisabled = true;

    });

    this.session.on("streamDestroyed", event => {
      console.log("STREAM DESTROYED", event.stream.name, this.streams.length);
      const idx = this.streams.indexOf(event.stream);
      if (idx > -1) this.streams.splice(idx, 1);

      if (event.stream.videoType == constants.StreamVideoType.Screen) {
        this.screenShareDisabled = false;
        return;
      }

      if (!event.stream.name.includes("AUDIO_CONNECTOR")) this.userDataService.DeleteTranslatedStreamId(event.stream);
      else return; //stream destroy audio_connector

      console.log("StreamDestroyed event streamId:" + event.stream.streamId, event.stream.name, event.reason, event.stream.videoType);
      this.endCall(null);
    });

    this.session.on("sessionDisconnected", event => this.setOpacity());

    this.checkScreenSharingAvailable();
  }

  ngAfterViewInit(): void {
    // Para poder acceder a los componentes hijos y sus m�todos
    this.publishers.changes.subscribe((changes) => {
      console.log('Publishers changed: ', changes, this.publishers.length);
    });

    this.subscribers.changes.subscribe((changes) => {
      console.log('Subscribers changed: ', changes, this.subscribers.length);
    });

    this.adjustButtonDisplay();
    window.addEventListener('resize', this.adjustButtonDisplay.bind(this));

    this.videoRect = this.videoContainer.nativeElement.getBoundingClientRect();
  }

  adjustButtonDisplay() {
    this.buttonsService.adjustButtonDisplay(this.container, this.buttonElements);
  }

  endCall(event: any) {
    if (event != null) event.stopPropagation();
    this.dialog.closeAll();

    this.userDataService.roomInfo?.roomParticipants.forEach(participant => {
      if (participant.audioConnectorId != null) {
        console.log("Force stop audioconnector. Call End", participant.id);
        this.audioConnectorService.stop(participant);
      }
    });

    if (this.session != null) {
      console.log("SESSION DESTROY");
      this.session.off('sessionDisconnected');
      this.session.off('sessionConnected');
      this.session.off('streamCreated');
      this.session.off('streamDestroyed');
      this.session.disconnect();
    }

    this.apiService.hangUp(this.userDataService.RoomId!, this.userDataService.Id!, this.userDataService.CallId).subscribe({
      next: data => {
        console.log("Hangup callId OK");
        this.goWaitingRoom();
      },
      error: err => {
        console.error("Error on Hangup: " + JSON.stringify(err));
        this.goWaitingRoom();
      }
    });

    this.signalRUnsubscribe();
  }

  goWaitingRoom(): void {
    this.router.navigate(['/waitingroom']);
  }

  switchMicrophone(event: any): void {
    if (event != null) event.stopPropagation();

    this.microActive = !this.microActive;
    const targetPublisher = this.publishers.find(publisher => publisher.publisherType == constants.PublisherType.Video);
    targetPublisher?.updateAudio(this.microActive);

    var participant = this.userDataService.roomInfo?.roomParticipants.find(p => p.id == this.userDataService.Id)!;
    participant.microphone = this.microActive;

    console.log("Silence is now: " + this.microActive);

    this.signalRService.updateMicrophone(this.userDataService.RoomId!, this.userDataService.Id!, this.microActive);

    var role = "E";
    if (this.userDataService.Role == constants.UserRole.Technician) role = "T";
    if (this.microActive) this.apiService.registerEvent("REDirectEvent", "(" + role + ") Mic on", "Activate microphone", this.userDataService.Id!);
    else this.apiService.registerEvent("REDirectEvent", "(" + role + ") Mic off", "Mute microphone", this.userDataService.Id!);
  }

  switchFlash(event: any): void {
    if (event != null) event.stopPropagation();

    this.flashOn = !this.flashOn;
    //TO DO
  }

  switchScreenSharing(event: any): void {
    if (event != null) event.stopPropagation();

    this.screenShareOn = !this.screenShareOn;

    if (this.screenShareOn) this.startScreenSharing();
    else this.stopScreenSharing();

    console.log("Switch: ", this.screenShareOn);
  }

  checkScreenSharingAvailable() {
    OT.checkScreenSharingCapability(response => {

      if (!response.supported || response.extensionRegistered === false) {
        console.log("not supported", response);
        this.screenShareAvailable = false;
        return;
      }

    });
  }

  startScreenSharing(): void {
    console.log("start screen sharing", this.screenShareOn);

    const targetPublisher = this.publishers.find(publisher => publisher.publisherType == constants.PublisherType.ShareScreen);
    targetPublisher?.createScreenSharePublisher();

    console.log("fin start screen sharing", this.screenShareOn);
    this.apiService.registerEvent("REDirectEvent", "(E) Share screen", "Screensharing", this.userDataService.Id!);
  }

  stopScreenSharing(): void {
    this.screenShareOn = false;

    console.log("stop screen sharing", this.screenShareOn);
    const targetPublisher = this.publishers.find(publisher => publisher.publisherType == constants.PublisherType.ShareScreen);

    if (targetPublisher?.publisher == null) return;

    console.log("destroy Publiser");
    targetPublisher.publisher.destroy();
  }

  startRecording(event: any): void {
    if (event != null) event.stopPropagation();

    this.recording = true;
    this.startTime = new Date();

    const targetSubscriber = this.subscribers.find(subscriber => subscriber.stream.name === TechnicianStream);
    this.apiService.startVideoRecording(this.userDataService.CallId!, targetSubscriber?.subscriber.getImgData()!, this.userDataService.otSession, this.userDataService.Id!, this.signalRService.getConnectionId()!, this.userDataService.RoomId!).subscribe(response => {
      console.log("response: " + response);
      this.videoId = response.videoId;
      this.recordingIntervalId = setInterval(() => {
      }, 1000);
    });
  }

  stopRecording(event: any): void {
    if (event != null) event.stopPropagation();

    this.recording = false;
    console.log('recording', this.recording);
    this.startTime = null;
    clearInterval(this.recordingIntervalId);

    this.openVideoTagsModal();

    this.apiService.stopVideoRecording(this.videoId, this.userDataService.Id!, this.signalRService.getConnectionId()!, this.userDataService.RoomId!).subscribe(response => {
      console.log("response: " + response);
    });
  }

  openTagsModal(event: any): void {
    if (event != null) event.stopPropagation();

    const dialogRef = this.dialog.open(TagsModalComponent, {
      width: '400px',
      data: {
        tags: this.userDataService.getTags()
      }
    });

    dialogRef.afterClosed().subscribe(tags => {
      console.log("tags", tags);
      if (tags.length == 0) return;
      this.userDataService.updateTags(tags);
      this.apiService.updateTags(this.userDataService.CallId, tags, this.userDataService.Id!).subscribe(response => {
        console.log("response: " + response);
      });
    });
  }

  openVideoTagsModal(): void {
    if (this.videoId == undefined) return;

    const dialogRef = this.dialog.open(TagsModalComponent, {
      width: '400px',
      data: {
        tags: []
      }
    });

    dialogRef.afterClosed().subscribe(tags => {
      console.log("video tags", tags);
      if (tags.length == 0) return;
      this.apiService.updateVideoTags(this.videoId, tags).subscribe(response => {
        console.log("response: " + response);
      });
    });
  }

  switchFullScreen(event: any): void {
    if (event != null) event.stopPropagation();

    this.fullScreen = !this.fullScreen;
    if (this.fullScreen) { this.requestFullScreen(); }
    else { this.exitFullScreen(); }
    console.log('switch full screen');
  }

  switchCamera(event: any) {
    if (event != null) event.stopPropagation();

    console.log('Cambiar de camara');
    const targetPublisher = this.publishers.find(publisher => publisher.publisherType == constants.PublisherType.Video);
    targetPublisher?.publisher.cycleVideo().then(console.log).catch(error => {
      console.log(error);
    });

    this.apiService.registerEvent("REDirectEvent", "(T) Switch camera", "Switch between device cameras", this.userDataService.Id!);
  }

  switchSection(section: string, event: any): void {
    if (event != null) event.stopPropagation();

    switch (section) {
      case 'chat':
        this.chatOn = !this.chatOn;
        //cierro el resto
        this.membersOn = false;
        this.chatService.updateChat();
        break;
      case 'members':
        this.membersOn = !this.membersOn;
        //cierro el resto
        this.chatOn = false;
        break;
    }
  }

  changeButtonsTo(barType: string, event: any): void {
    switch (barType) {
      case 'BASIC':
        this.currentnavBar = constants.NavBars.BASIC;
        break;
      case 'RESOLUTION':
        this.currentnavBar = constants.NavBars.RESOLUTION;
        break;
      case 'EDITCAPTURE':
        this.currentnavBar = constants.NavBars.EDITCAPTURE;
        break;
      default:
        this.currentnavBar = constants.NavBars.BASIC;
        break;
    }
  }

  setResolution(resolution: number, event: any): void {
    if (event != null) event.stopPropagation();

    this.resolution = resolution;
    //TO DO
    this.changeButtonsTo('BASIC', event);
  }

  registerConnectionId(connectionId: string | undefined, roomId: number, userId: number | null) {
    this.apiService.registerConnectionId(this.userDataService.CallId, connectionId, roomId, userId).subscribe(response => {
      console.log("Received registerConnectionId " + response);
    });
  }

  setOpacity(): void {
    if (this.isExpert) {
      this.opacity = constants.NoVideoOpacityExpert;
    }
    else {
      this.opacity = constants.NoVideoOpacityTechnician;
    }
  }

  requestFullScreen() {
    const document: any = window.document;
    const element: any = document.documentElement;

    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      element.webkitRequestFullscreen();
    } else if (element.mozRequestFullScreen) {
      element.mozRequestFullScreen();
    } else if (element.msRequestFullscreen) {
      element.msRequestFullscreen();
    }
  }

  exitFullScreen() {
    const document: any = window.document;

    if (document.exitFullscreen) {
      document.exitFullscreen();
    } else if (document.webkitExitFullscreen) {
      document.webkitExitFullscreen();
    } else if (document.mozCancelFullScreen) {
      document.mozCancelFullScreen();
    } else if (document.msExitFullscreen) {
      document.msExitFullscreen();
    }
  }

  signalREventsHook() {

    this.roomDeletedSubscription = this.signalRService.onRoomDeleted.subscribe(response => {
      console.error("Room : " + response + " deleted");
      console.error("Strange thing, a room can be deleted if some user is in call module");
    });

    this.imageReceivedSubscribtion = this.signalRService.onImageReceived.subscribe(response => {
      this.openImage(response);
    });

    this.remoteSubscribtion = this.signalRService.onRemoteRecording.subscribe(data => {
      this.recording = data.isRecording;
      console.log("remote recording", data);
    });

    this.HDImageSubscription = this.signalRService.onHDImageRequest.subscribe(senderSRId => {
      this.HdImageCaptureAndSend(senderSRId);
    });

    this.audioTranslatedReady = this.signalRService.onPublishTranslatedAudio.subscribe(data => {
      console.log("onPublishTranslatedAudio...");
      this.audioConnectorService.publishTranslatedAudio(this.publishers, data);
    });

    this.startAudioConnector = this.signalRService.onStartAudioConnector.subscribe(data => {
      console.log("onStartAudioConnector...");
      this.audioConnectorService.startAudioTranslate(this.publishers, data);
    });

    this.stopAudioConnector = this.audioConnectorService.onStopAudioConnector.subscribe(() => {
      console.log("onStopAudioConnector...");
      this.audioConnectorService.stopAudioTranslate(this.publishers);
    });

    this.transcriptionReady = this.signalRService.onTranscriptionReady.subscribe(data => {
      console.log("onTranscriptionReady", data);
      this.subtitle = data;
      setTimeout(() => this.subtitle = "", 4000);
    });

    this.subtitleReady = this.signalRService.onSubtitleReady.subscribe(data => {
      console.log("onSubtitleReady", data);
      var participants = this.userDataService.roomInfo!.roomParticipants;
      var participant = participants.find(p => p.id == this.userDataService.Id);
      if (participant?.translateSubtitleOn) {
        this.subtitle = data;
        setTimeout(() => this.subtitle = "", 4000);
      }
    });

    this.receivePointer = this.signalRService.onReceivePointer.subscribe(data => {
      var participant = this.userDataService.roomInfo.roomParticipants.find(p => p.id == data.userId);
      if (participant == undefined || this.videoRect == null) return;

      const { x, y } = this.pointerService.getVideoPosition(this.elRef, data);
      if (x == null) return;

      participant.pointer = { x: x, y: y };

    });

  }

  showPointer(event: MouseEvent): void {
    if (!this.videoRect) return;

    var participant = this.userDataService.roomInfo.roomParticipants.find(p => p.id == this.userDataService.Id);
    if (participant == undefined) return;

    const video = this.pointerService.getNonZeroVideoElement(this.elRef);
    if (video == null) return;
    const rect = video.getBoundingClientRect();

    console.log(`Rect at (${rect.left}, ${rect.top})`);

    const { xPercent, yPercent } = this.pointerService.getPercents(event, rect, video.videoWidth, video.videoHeight);
    if (xPercent == null) return;

    const { clickX, clickY } = this.pointerService.getPositionClickEvent(event, rect);

    participant.pointer = { x: clickX, y: clickY };

    this.signalRService.sendPointer(this.userDataService.RoomId!, participant.id, xPercent, yPercent);
  }


  captureAndEdit(event: any) {
    if (event != null) event.stopPropagation();

    if (this.isExpert) {
      const targetSubscriber = this.subscribers.find(subscriber => subscriber.stream.name === TechnicianStream);
      let base64Image = targetSubscriber?.subscriber.getImgData();
      if (base64Image && base64Image?.length > 0) {
        console.log("* Capture and edit");
        this.editImage(base64Image, false);
      }
    }
  }

  editImage(image: string, isHdImage: boolean) {
    console.log("* Edit image", isHdImage);
    let dialogEditor = this.dialog.open(ImageEditorComponent, {
      panelClass: 'edit-image-dialog',
      data: { img: image, mode: constants.ImageEditorModes.Edit, isExpert: this.isExpert, isHdImage: isHdImage },
    });

    dialogEditor.afterClosed().subscribe((result: ImageEditorResultData) => {
      this.processImageEditorResponse(result);
    });

  }


  processImageEditorResponse(result: ImageEditorResultData) {
    console.log("* Process image edit response");
    console.log("Close by :" + constants.ImageEditorActions[result.action]);
    switch (result.action) {
      case constants.ImageEditorActions.saveAndClose: {
        this.apiService.sendImage(this.userDataService.CallId!, result.originalImageBase64, this.userDataService.Id!, result.send,
          this.signalRService.getConnectionId()!, this.userDataService.RoomId!, false, result.isHdImg).subscribe({
            next: data => {
              this.openSnackBar(data.message);
            },
            error: err => {
              this.openSnackBar(err.error);
            }
          });

        if (result.isLiveDrawing) this.signalRService.liveDrawingClose(this.userDataService.RoomId!, this.userDataService.Id!);

        break;
      }
      case constants.ImageEditorActions.RemoteClose: {
        this.signalRService.liveDrawingClose(this.userDataService.RoomId!, this.userDataService.Id!);
        break;
      }

      default: {
        console.log('The dialog was closed, nothing to do');
        break;
      }
    }
  }

  openSnackBar(message: string, duration: number = constants.snackBarDurationInSeconds) {
    this.snackBar.openFromComponent(NotificationSnackComponent, {
      duration: duration * 1000, data: { message: message }
    });
  }

  openImage(imageReceived: ImageReceived) {
    console.log("SignalR Image received is HD: " + imageReceived.isHdImage);

    console.log("* Open image", imageReceived.isHdImage, imageReceived.isLiveDrawing);

    if (imageReceived.isHdImage) {
      this.editImage(imageReceived.base64Image, imageReceived.isHdImage);
      return;
    }

    console.log("* Open image receive edit component", imageReceived.isHdImage, imageReceived.isLiveDrawing);

    let dialogRef: any;
    console.log("editordialofg is :" + dialogRef);
    if (imageReceived.base64Image && imageReceived.base64Image?.length > 0) {
      dialogRef = this.dialog.open(ImageReceivedEditorComponent, {
        data: {
          img: imageReceived.base64Image,
          isLiveMode: imageReceived.isLiveDrawing,
          mode: constants.ImageEditorModes.LiveDraw,
          isExpert: this.isExpert,
          isHdImage: imageReceived.isHdImage
        },
      });

      dialogRef!.afterClosed().subscribe((result: ImageEditorResultData) => {
        this.processImageEditorResponse(result);
        dialogRef = null;

      });
    } else {
      this.translateService.get('IMAGE.NOT_VALID').subscribe((text: string) => {
        this.openSnackBar(text)
      });

    }
  }

  getRecordingTime(): string {
    if (this.startTime) {
      const currentTime = new Date();
      const elapsedSeconds = Math.floor((currentTime.getTime() - this.startTime.getTime()) / 1000);
      const minutes = Math.floor((elapsedSeconds % 3600) / 60);
      const seconds = elapsedSeconds % 60;
      return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    } else {
      return '00:00';
    }
  }

  signalRUnsubscribe() {
    console.log("Unsubscribing");
    this.roomDeletedSubscription.unsubscribe();
    this.imageReceivedSubscribtion.unsubscribe();
    this.remoteSubscribtion.unsubscribe();
    this.HDImageSubscription.unsubscribe();
    this.audioTranslatedReady.unsubscribe();
    this.startAudioConnector.unsubscribe();
    this.stopAudioConnector.unsubscribe();
    this.transcriptionReady.unsubscribe();
    this.subtitleReady.unsubscribe();
    this.receivePointer.unsubscribe();
  }

  requestHDCapture(event: any) {
    if (event != null) event.stopPropagation();

    this.apiService.requestHDImage(this.userDataService.CallId!, this.userDataService.Id!, this.signalRService.getConnectionId()!, this.userDataService.RoomId!).subscribe(resp => {
      console.log(resp);
      this.openSnackBar('CALL.HD_IMAGE_REQUESTED');
    });

  }

  HdImageCaptureAndSend(senderSRId: string) {
    if (!this.isExpert) {
      //let hdImageStr = this.opentokService.publisher.getImgData();
      const targetSubscriber = this.publishers.find(publiserComp => publiserComp.publisherType == constants.PublisherType.Video);
      let hdImageStr = targetSubscriber?.publisher.getImgData();
      this.apiService.sendHdImage(this.userDataService.CallId!, hdImageStr!, this.userDataService.Id!, false, this.signalRService.getConnectionId()!, this.userDataService.RoomId!, senderSRId).subscribe(resp => {
        console.log(resp);
      });
    }
  }

}

