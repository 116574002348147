<div class="background">
    <div class="logo"></div>
    <div class="translate">
            <mat-form-field class="translateselect">
                <mat-select #langSelect (selectionChange)="onSelectChange(langSelect.value)"  [(value)]="currentLang" panelwidth="150px">
                  <mat-option *ngFor="let lang of langs" [value]="lang">{{lang}}</mat-option>
                </mat-select>
              </mat-form-field>
        </div>
        <div class="autoLink">
          <div style="padding-right: 5px;"><p id="getLink"></p></div>
          <div class="link" (click)="getLink()"></div>
        </div>

</div>