import { Component, Input} from '@angular/core';
import { CommonModule } from '@angular/common';
import { chatMessage } from 'src/app/models/chatMessage';
import { MatDialog } from '@angular/material/dialog';
import { MediaPreviewComponent } from './media-preview/media-preview.component';
import { MatDialogModule } from '@angular/material/dialog';
import { PipesModule } from '../../../pipes/pipes.module';
import { FileHelperService } from 'src/app/services/file-helper.service';
import { UserDataService } from 'src/app/services/userData.service';
import { TranslateModule } from '@ngx-translate/core';
import { ChatService } from '../../../services/chat.service';


@Component({
  selector: 'directCall-chat-message',
  standalone: true,
  imports: [CommonModule, MatDialogModule, PipesModule, TranslateModule],
  templateUrl: './message.component.html',
  styleUrls: ['./message.component.scss']
})


export class MessageComponent {
  @Input() chatMessage: chatMessage = new chatMessage( -1,'');
  currentUserId : number;
  color?:string;
  backgroundColor?: string;
  borderColor?: string;

  constructor(public dialog: MatDialog, public fileHelperService: FileHelperService, private userDataService: UserDataService, private chatService: ChatService) {
      this.currentUserId = userDataService.Id!;
      this.chatMessage = new chatMessage(this.currentUserId, '');
  }

  
  ngOnInit() {
    this.color = this.chatMessage.color;
    this.backgroundColor = `rgba(${this.chatService.getBackgroundColor(this.chatMessage.color!)}, 0.1)`;
    this.borderColor = `1px solid ${this.chatMessage.color}`;
    console.log('color', this.chatMessage.color);
  }


  openMediaModal(file: any): void {
    this.dialog.open(MediaPreviewComponent, {
      width: '80%',
      data: file 
    });
  }
}
