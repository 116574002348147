import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { ConfigService } from 'src/app/services/config.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { UserDataService } from 'src/app/services/userData.service';


@Component({
  selector: 'app-header',
  standalone: true,
  imports: [CommonModule, MatSelectModule, MatFormFieldModule, TranslateModule],
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  langs: string[] = [];
  currentLang;
  constructor(private translate: TranslateService, private configurationService: ConfigService, private apiService: ApiService, private userDataService: UserDataService) {
    this.langs = this.translate.getLangs();
    this.currentLang = this.translate.currentLang;
    const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));
  }

  onSelectChange(lang: string) {
    this.translate.use(lang);
    this.configurationService.changeLanguage(lang);
  }


  getLink() {
    console.log("get link");
    let divLink = document.getElementById('getLink') as HTMLElement;
    this.translate.get('LINKS.GETTING').subscribe((text: string) => {
      divLink.innerHTML = text;
    });

    this.apiService.getUpdatedToken(this.userDataService.Id!, this.userDataService.RoomId!).subscribe({
      next: data => {
        let link = document.location.origin + "/?token=" + data.token;
        console.log(link);
        navigator.clipboard.writeText(link);
        this.translate.get('LINKS.COPIED').subscribe((text: string) => {
          divLink.innerHTML = text;
        });
      },
      error: err => {
        console.error(err.error);
      }
    });

    this.cleanLinks();

  }

  cleanLinks() {
    setTimeout(function () {
      let divLink = document.getElementById('getLink') as HTMLElement;
      divLink.innerHTML = "";
    }, 2500);
  }
}

