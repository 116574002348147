import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class FileHelperService {

  constructor(private sanitizer: DomSanitizer) { }

  isURLVideo(fileUrl: string): boolean { 
    //Esto habrá que cambiarlo cuando las imágenes vengan de servidor porque seguramente no tengan extensión
    return fileUrl.toLowerCase().endsWith('.mp4') ||
           fileUrl.toLowerCase().endsWith('.avi') ||
           fileUrl.toLowerCase().endsWith('.mov');
  }

  isFileVideo(file: File): boolean {
    if(file.type.startsWith('video/')){
        console.log('el archivo es de tipo video', file.name);}
    return file.type.startsWith('video/');
  }

  isFileImage(file: File): boolean {
    if(file.type.startsWith('image/')){
    console.log('el archivo es de tipo imagen', file.name);}
    return file.type.startsWith('image/');
  }

  convertFileToUrl(file: File): SafeUrl  {
    return this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(file));
  }

  isUrl(fileOrUrl: any): boolean {
    return typeof fileOrUrl === 'string';
  }

}