export class chatMessage {
  constructor(
    public userId: number,
    public message: string,
    public date: Date = new Date(),
    public participantName?: string,
    public translation?: string,
    public color?: string,
    public file?: any
  ) { }
}

export interface chatMessageRequest {
  FromUserId: number;
  SignalRConnectionId: string;
  RoomId: number;
  Translate: boolean;
  TranslateLanguage: string;
  ChatMessage: chatMessage;
}
