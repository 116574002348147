import { Component, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { FabricService } from 'src/app/services/fabric.service';
import * as constants from 'src/app/constants/constants';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ImageEditorDialogData } from 'src/app/interfaces/imageeditor-dialogdata';
import { ModifiedObjectsEmitterData } from 'src/app/interfaces/SignalRDrawObject';
import { ImageEditorResultData } from 'src/app/interfaces/imageeditor-resultdata';
import { ApiService } from 'src/app/services/communications/api.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { UserDataService } from 'src/app/services/userData.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-ImageReceivedEditor',
  templateUrl: './ImageReceivedEditor.component.html',
  styleUrls: ['./ImageReceivedEditor.component.css'],
  standalone: true,
  imports: [CommonModule]
})


export class ImageReceivedEditorComponent  {

  @ViewChild('canvasRec', { static: true }) canvasRef!: ElementRef<HTMLCanvasElement>;

  readonly EDITOR_CONSTANTS = constants.ImageEditorModes;
  editorMode: constants.ImageEditorModes;
  imageLink: string = "data:image/png;base64, ";
  fabricService!: FabricService;


  constructor(public dialogRef: MatDialogRef<ImageReceivedEditorComponent>, @Inject(MAT_DIALOG_DATA) public data: ImageEditorDialogData, private signalRService: SignalRService, private apiService: ApiService, private userDataService: UserDataService) {
    this.imageLink = this.imageLink + data.img;
    this.dialogRef.disableClose = true;
    console.log("editor mode received:" + constants.ImageEditorModes[data.mode]);
    this.editorMode = data.mode;
    this.fabricService = new FabricService(this.imageLink, this.data.isExpert, this.data.isLiveMode, this.userDataService.Id!);
  }

  ngAfterViewInit() {
    console.log("loadCanvas image");
    this.fabricService.loadCanvas(this.canvasRef);
    this.fabricService.loadImg();
    this.eventsSubscribe();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.fabricService.adjustCanvasSize();
  }

  close() {
    console.log("Close clicked editor mode:" + constants.ImageEditorModes[this.editorMode]);
    let closeAction: constants.ImageEditorActions = this.editorMode === constants.ImageEditorModes.LiveDraw ? constants.ImageEditorActions.RemoteClose : constants.ImageEditorActions.Close;
    this.dialogRef.close({ action: closeAction, originalImageBase64: this.data.img, send: false, isHdImg: this.data.isHdImage });
  }

  saveAndClose() {
    console.log("Close clicked editor mode:" + constants.ImageEditorModes[this.editorMode]);
    this.dialogRef.close({ action: constants.ImageEditorActions.saveAndClose, originalImageBase64: this.data.img, send: false, isHdImg: this.data.isHdImage });
  }

  freeDrawing() {
    this.fabricService.freeDrawing(true);
  }

  eraseAll() {
    this.fabricService.eraseAll();
  }

  eventsSubscribe() {
    this.fabricService.OnObjectModified.subscribe(response => {
      if (this.editorMode === this.EDITOR_CONSTANTS.LiveDraw || !this.data.isExpert) {
        this.signalRService.SendCanvasObject(this.userDataService.Id!, this.userDataService.RoomId!, response);
        console.log("canvas onject sent :" + JSON.stringify(response));
      }
    });

    this.signalRService.onCanvasObjectReceived.subscribe((data: ModifiedObjectsEmitterData) => {
      console.log("liveObject received");
      this.fabricService!.liveObject(data);
    });

    this.signalRService.OnLiveDrawingClose.subscribe(() => {
      this.closeLiveDrawing();
    });

  }

  closeLiveDrawing() {
    console.log("Close Live Drawing", this.data.isLiveMode);
    const result: ImageEditorResultData = {
      action: constants.ImageEditorActions.RemoteClose,
      originalImageBase64: this.data.img,
      send: false,
      isLiveDrawing: this.data.isLiveMode,
      isHdImg: this.data.isHdImage
    };
    this.dialogRef.close(result);
  }

}
