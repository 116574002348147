<div style="background-color: #161A29">
  <div id="floatButtons" class="float-buttons" *ngIf="!this.data.isExpert">
    <div class="round-button close-button float-button">
      <img src="/assets/images/buttons/close.svg" alt="Icono" (click)="close()">
    </div>
    <div class="round-button capture-tool-button float-button" *ngIf="this.data.isLiveMode">
      <img src="/assets/images/buttons/capture/eraser.svg" alt="eraser" title="eraser" (click)="eraseAll()">
    </div>
  </div>

  <div id="imageContainer" class="divContainer">
    <canvas id="canvasSurface" class="divContainer" #canvasRec></canvas>
  </div>
</div>
