import { Component, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from '../header/header.component';
import { TranslateModule } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ChatComponent } from '../chat/chat.component';
import { MembersComponent } from '../members/members.component';
import { UserDataService } from 'src/app/services/userData.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { LoaderComponent } from '../loader/loader.component';
import { RoomInfo } from '../../interfaces/RoomInfo';
import { ChatService } from 'src/app/services/chat.service';
import { CallNotificationInfo } from 'src/app/interfaces/call-notifications';
import { MatDialog } from '@angular/material/dialog';
import { CallNotificationComponent } from '../call-notification/call-notification.component';
import { CallingComponent } from '../calling/calling.component';
import { environment } from 'src/environment/environment';
import { Subscription } from 'rxjs';
import { UserRole } from '../../constants/constants';
import { ModalComponent } from '../modal/modal.component';


@Component({
  selector: 'app-waitingroom',
  standalone: true,
  imports: [CommonModule, HeaderComponent, TranslateModule, ChatComponent, MembersComponent, LoaderComponent],
  templateUrl: './waitingroom.component.html',
  styleUrls: ['./waitingroom.component.scss']
})

export class WaitingroomComponent implements OnDestroy {

  @ViewChild(MembersComponent) membersComponent!: MembersComponent;

  userId: number;
  userName: string;
  roomId: number;
  isRoomReady: boolean = false;
  error: string = "";
  roomInfo: RoomInfo | null = null;
  otherParticipantOnline: boolean = false; //TODO
  titleText: string = '';
  descriptionText: string = '';
  buttonText: string = '';
  members = false;
  backgroundImage: string = 'url(assets/images/users/background-technician-none.svg)';
  showLoader: boolean = false;

  incommingCallDialog: any = null;
  callDialog: any = null;

  onRoomDeletedSubscription!: Subscription;
  onUpdateParticipantSubscription!: Subscription;
  onCallNotificationSubscription!: Subscription;
  onCallNotificationResponseSubscription!: Subscription;


  constructor(private router: Router, private userDataService: UserDataService, private apiService: ApiService, private signalRService: SignalRService,
    private chatService: ChatService, public dialog: MatDialog) {
    this.userId = userDataService.Id!;
    this.userName = userDataService.Name!;
    this.roomId = userDataService.RoomId!;
  }

  ngOnDestroy(): void {
    this.signalREventUnSubscribe();
    this.showLoader = false;
  }

  ngOnInit(): void {
    this.getRoomInfo(this.roomId);
    this.signalREventSubscribe();
  }

  switchMembers(): void {
    this.members = !this.members;
  }

  makeCall(): void {
    console.log("go Call");
    if (!this.otherParticipantOnline)
      return;

    this.callDialog = this.dialog.open(CallingComponent, {
      panelClass: "callingModal",
      minWidth: '25%',
      data: { timeout: environment.CallNotificationDurationSeconds },
    });

    this.callDialog!.afterClosed().subscribe((result: boolean) => {
      console.log("Close notification call", result);
      //if (result) this.startCall();
    });

    var role = "E";
    if (this.userDataService.Role == UserRole.Technician) role = "T";
    console.log("start call: role", role);
    this.apiService.registerEvent("REDirectEvent", "(" + role + ") Make call", "Start a call", this.userDataService.Id!);
  }

  startCall() {
    this.callDialog = null;
   
    console.log("startCall");
    this.apiService.startCall(this.userDataService.RoomId!, this.userDataService.Id!, this.userDataService.Role!).subscribe({
      next: data => {
        console.log("Received call data:" + JSON.stringify(data));
        this.userDataService.otCallToken = data.callToken;
        this.userDataService.otSession = data.session;
        this.userDataService.otApiKey = data.apiKey;
        this.userDataService.CallId = data.callId;

        this.router.navigate(['/call']);
      },
      error: err => {
        // set button to red
        console.log("Error on startCall :" + JSON.stringify(err));

        this.dialog.open(ModalComponent, {
          data: {
            title: 'MODAL.ERROR_TITLE',
            message: 'STARTCALL_ERROR'
          }
        });

      }
    });

  }

  getStrings(): void {
    if (this.otherParticipantOnline) {
      this.buttonText = 'WAITINGROOM.BUTTON.ONLINE';
      if (this.userDataService.isExpert()) {
        this.titleText = 'WAITINGROOM.TITLE.EXPERT.ONLINE';
        this.descriptionText = 'WAITINGROOM.DESCRIPTION.EXPERT.ONLINE';
      } else {
        this.titleText = 'WAITINGROOM.TITLE.TECH.ONLINE';
        this.descriptionText = 'WAITINGROOM.DESCRIPTION.TECH.ONLINE';
      }
    } else {
      this.buttonText = 'WAITINGROOM.BUTTON.OFFLINE';
      if (this.userDataService.isExpert()) {
        this.titleText = 'WAITINGROOM.TITLE.EXPERT.OFFLINE';
        this.descriptionText = 'WAITINGROOM.DESCRIPTION.EXPERT.OFFLINE';
      } else {
        this.titleText = 'WAITINGROOM.TITLE.TECH.OFFLINE';
        this.descriptionText = 'WAITINGROOM.DESCRIPTION.TECH.OFFLINE';
      }
    }
  }

  getRoomInfo(roomId: number) {
    this.showLoader = true;
    this.apiService.getRoomInfo(roomId, this.userId).subscribe({
      next: data => {
        this.userDataService.roomInfo = data;
        this.roomInfo = this.userDataService.roomInfo;

        console.debug("Room info received " + JSON.stringify(data));

        if (this.userDataService.roomInfo.roomParticipants.length > 0)
          this.otherParticipantOnline = this.userDataService.roomInfo.roomParticipants!.every(o => o.isInRoom);

        this.isRoomReady = true;
        this.checkParticipants();

        this.membersComponent.updateTranscriptionsEnabled(this.userDataService.roomInfo.transcriptionsEnabled);

        this.showLoader = false;
        this.chatService.updateChat();
      },
      error: error => {
        this.isRoomReady = false;
        error = "error getting room info";
        console.error('There was an error!', error.message);
        this.getStrings();
        this.showLoader = false;
      }
    });

  }

  signalREventSubscribe() {
    this.onRoomDeletedSubscription = this.signalRService.onRoomDeleted.subscribe(response => {
      console.error("Room : " + response + " deleted");
    });

    this.onUpdateParticipantSubscription = this.signalRService.onUpdateParticipant.subscribe(() => {
      console.log("** onUpdateParticipant - checkParticipants");
      this.checkParticipants();
    });

    this.onCallNotificationSubscription = this.signalRService.onCallNotification.subscribe((data) => {
      if (data.active) {
        console.log("Call notification received");
        this.openCallNotification(data);
      }
      else
        this.endDialog(data.active);
    });

    this.onCallNotificationResponseSubscription = this.signalRService.onCallNotificationResponse.subscribe((result) => {
      console.log("Call notification response arrived. Call accepted: " + result);
      if (this.callDialog != undefined) this.callDialog.close(result);
      if (result) this.startCall();
    });
  }

  checkParticipants() {
    let roomParticipants = this.userDataService.roomInfo?.roomParticipants;
    console.log("checkParticipants", roomParticipants);
    if (roomParticipants) {
      this.otherParticipantOnline = roomParticipants!.length > 0 && roomParticipants!.some(o => o.id != this.userDataService.Id! && o.isInRoom == true);
      this.getStrings();
      this.chatService.assignColorsToParticipants(this.userId); //sólo para el chat
    } else {
      console.log("No room participants");
    }
  }

  openCallNotification(callNotificationInfo: CallNotificationInfo) {

    console.log("Open call notification dialog");

    let caller = this.userDataService.roomInfo?.roomParticipants.find(o => o.id == callNotificationInfo.callerId);

    this.incommingCallDialog = this.dialog.open(CallNotificationComponent, {
      panelClass: "callingModal",
      minWidth: '25%',
      data: {
        callerName: caller?.name,
      },
    });


    this.incommingCallDialog!.afterClosed().subscribe((result: boolean) => {
      console.log("After close dialog result: " + result);
      this.sendCallNotificationResult(result);
      //if (result) {
      //  this.startCall();
      //}
    });

  }

  endDialog(result: boolean) {
    this.incommingCallDialog!.close(result);
  }

  sendCallNotificationResult(result: boolean) {
    this.apiService.callNotificationResponse(this.userDataService.RoomId!, this.signalRService.getConnectionId()!, result).subscribe({
      next: () => {
        console.log("Response sent ok: " + result);
      },
      error: err => {
        console.log("Error on sending rejected call:" + JSON.stringify(err));
      }
    });
  }

  signalREventUnSubscribe() {
    console.log("Signal R unsubscrive to events");
    this.onRoomDeletedSubscription.unsubscribe();
    this.onUpdateParticipantSubscription.unsubscribe()
    this.onCallNotificationSubscription.unsubscribe();
    this.onCallNotificationResponseSubscription.unsubscribe();
  }
}

