import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { MatCardModule } from '@angular/material/card';
import { MatInputModule } from '@angular/material/input';
import { MatChipsModule } from '@angular/material/chips';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'directCall-tags-modal',
  standalone: true,
  imports: [CommonModule, FormsModule, MatIconModule, MatCardModule, MatChipsModule, MatInputModule, MatButtonModule],
  templateUrl: './tags-modal.component.html',
  styleUrls: ['./tags-modal.component.scss']
})

export class TagsModalComponent {
  tags: string[] = [];

  constructor(public dialogRef: MatDialogRef<TagsModalComponent>,  @Inject(MAT_DIALOG_DATA) public data: any) {
    this.tags = data.tags;
  }

  addTag(input: HTMLInputElement): void {
    const value = input.value.trim();
    if (value && this.tags && !this.tags.includes(value)) {
      this.tags.push(value);
    }
    input.value = '';
  }

  removeTag(tag: string): void {
    const index = this.tags?.indexOf(tag);
    if (index >= 0) {
      this.tags?.splice(index, 1);
    }
  }

  closeModal(): void {
    this.dialogRef.close(this.tags);
  }
}
