import { Component, Inject, OnInit, inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar, MatSnackBarAction, MatSnackBarActions, MatSnackBarLabel, MatSnackBarModule, MatSnackBarRef, } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';
import { SnackbarData } from 'src/app/interfaces/snackbar-data';

@Component({
  selector: 'app-notificationSnak',
  templateUrl: './notificationSnack.component.html',
  styleUrls: ['./notificationSnack.component.css'],
  standalone: true,
  imports : [MatSnackBarModule,TranslateModule]

})
export class NotificationSnackComponent {

  message: string;

  snackBarRef = inject(MatSnackBarRef);
  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SnackbarData) {
    this.message = data.message;
  }


}
