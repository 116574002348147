export const defaultText = "Insert text"
export const strokeWidth = 6;
export const squareSize: number = 100;


export const objectActionAdd = 'add';
export const objectActionRemove = 'remove';

export const defaultEditorColor = '#FFFF00';
export const technicalColor = '#FF00FF';

