<div class="directCallChat panel" [class.stickyMode]="stickyMode">
  

  <div *ngIf="!stickyMode" class="close" (click)="closeChat()">
    <label class="close-icon">
      <i class="material-icons">close</i>
    </label>
  </div>

  <div #messagesContainer class="messagesContainer" [class.scroll-animated]="!firstLoad">



    <div *ngFor="let messageGroup of group">
      <div class="dateDivider">
        <div class="date">{{ messageGroup[0].date | date:'mediumDate' }}</div>
        <div class="line"></div>
      </div>
      <div *ngFor="let message of messageGroup">
        <directCall-chat-message [chatMessage]="message"></directCall-chat-message>
      </div>
    </div>
  </div>
  <div class="newText">
    <label  for="fileInput" class="file-input-icon">
      <i *ngIf="allowFiles"class="material-icons">attach_file</i>
    </label>
    <input #fileInput id="fileInput" type="file" (change)="onFileSelected($event)" accept="image/*,video/*">
    <textarea class="inputText" [(ngModel)]="newMessage.message" id="chatInput" (input)="fixHeight()" (keyup.enter)="addNewMessage()"></textarea>
    <img id="sendChat" src="/assets/images/chat/send.svg" class="sendButton" (click)="addNewMessage()">
  </div>

  <div *ngIf="newMessage.file" class="attachment-info">
    <p class="attachment-name">{{ newMessage.file.name }}</p>
    <i (click)="clearAttachment()" class="material-icons">delete_outline</i>
  </div>
</div>
