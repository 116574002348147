import { ErrorHandler } from '@angular/core';

import * as Elmahio from 'elmah.io.javascript';
import { environment } from 'src/environment/environment';

export class ElmahIoErrorHandler implements ErrorHandler {
  logger: Elmahio;
  constructor() {
    this.logger = new Elmahio({
      apiKey: environment.elmahKey,
      logId: environment.elmahid
    });
  }
  handleError(error: any) {
    if (error && error.message) {
      this.logger.error(error.message, error);
    } else {
      this.logger.error('Error in application', error);
    }
  }
}