<mat-card>
  <mat-card-header>
    <mat-card-title>Agregar Etiquetas</mat-card-title>
  </mat-card-header>  
  <mat-card-content style="padding-top: 20px">
    <mat-form-field style="width: 100%">
      <mat-chip-listbox #chipList>
        <mat-chip *ngFor="let tag of tags" [removable]="true" (removed)="removeTag(tag)">
          {{tag}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
        <input matInput placeholder="Agregar etiquetas..." (keydown.enter)="addTag(input)" #input>
      </mat-chip-listbox>
    </mat-form-field>
  </mat-card-content>
  <mat-card-actions align="end">
    <button mat-raised-button color="primary" (click)="closeModal()">Cerrar</button>
  </mat-card-actions>
</mat-card>
