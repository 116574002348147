import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { WaitingroomComponent } from './components/waitingroom/waitingroom.component';
import { CallComponent } from './components/call/call.component';
import { LoaderComponent } from './components/loader/loader.component';



const routes: Routes = [
  { path: '', component: LoaderComponent },
  { path: 'waitingroom', component: WaitingroomComponent },
  { path: 'call', component: CallComponent },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

