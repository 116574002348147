import { ElementRef, EventEmitter, Inject } from '@angular/core';
import { fabric } from 'fabric';
import * as canvasConstants from 'src/app/constants/canvas-constants';
import { ModifiedObjectsEmitterData, SignalRDrawObject } from '../interfaces/SignalRDrawObject';


export class FabricService {

  OnObjectModified = new EventEmitter<ModifiedObjectsEmitterData>();

  public actualColor: string = canvasConstants.defaultEditorColor;
  private canvas!: fabric.Canvas;
  private IsReady: boolean = false;
  private originaImageLink: string;

  private minWidthHDResolution: number = 1280;
  private minHeightHDResolution: number = 720;
  private initialCanvasWidth!: number;
  private initialCanvasHeight!: number;

  constructor(@Inject('imageLink') private imageLink: string, @Inject('isExpert') private isExpert: boolean, @Inject('isLive') private isLive: boolean, private userId: number) {
    this.originaImageLink = imageLink;
  }

  public loadCanvas(canvasRef: ElementRef<HTMLCanvasElement>) {
    this.canvas = new fabric.Canvas(canvasRef.nativeElement, {
      selection: false,
      preserveObjectStacking: true,
    });
  }

  public loadImg() {
    var imageElement = document.createElement("img");
    imageElement.setAttribute("src", this.originaImageLink);

    imageElement.onload = () => {
      var imgInstance = new fabric.Image(imageElement);

      const maxWidth = Math.min(this.minWidthHDResolution, window.innerWidth / 10 * 8);
      const maxHeight = Math.min(this.minHeightHDResolution, window.innerHeight / 10 * 8);

      var imageWidth = maxWidth;
      var imageHeight = imageElement.height * imageWidth / imageElement.width;
      if (imageHeight > maxHeight) {
        imageHeight = maxHeight;
        imageWidth = imageElement.width * imageHeight / imageElement.height;
      }

      this.canvas.setDimensions({ width: imageWidth, height: imageHeight });

      this.canvas.setBackgroundImage(imgInstance, this.canvas.renderAll.bind(this.canvas!), {
        excludeFromExport: true,
        scaleX: this.canvas.width! / imageElement.width,
        scaleY: this.canvas.height! / imageElement.height
      });

      this.initialCanvasWidth = imageWidth;
      this.initialCanvasHeight = imageHeight;

      if (this.isLive) this.isCanvasReady();
    };
  }


  public isCanvasReady(): boolean {
    try {
      if (!this.IsReady) {
        console.log("Preparing Canvas...");

        this.IsReady = true;
        if (!this.isExpert) {
          this.actualColor = canvasConstants.technicalColor;
          this.freeDrawing(true);
        }

        let that = this;
        var modifiedHandler = function (evt: any) {
          console.log("modifiedHandler", evt);
          var modifiedObject = evt.target;
          that.objectModified();
        };

        console.log("Modified");
        this.canvas.on('object:modified', modifiedHandler);
        return this.IsReady;
      }
      else {
        return true;
      }

    }
    catch (e) {
      console.log("Creating canvas err: " + e);
      return false;
    }
  }

  adjustCanvasSize() {
    const containerWidth = window.innerWidth * 0.8; 
    const containerHeight = window.innerHeight * 0.8; 

    const scaleX = containerWidth / this.initialCanvasWidth;
    const scaleY = containerHeight / this.initialCanvasHeight;
    const scale = Math.min(scaleX, scaleY);

    this.canvas.setWidth(this.initialCanvasWidth * scale);
    this.canvas.setHeight(this.initialCanvasHeight * scale);
    this.canvas.setZoom(scale);
    this.canvas.renderAll();
  }

  addRectangle() {
    this.canvas!.isDrawingMode = false;
    this.canvas!.on("mouse:up", (e) => {
      const rect: fabric.Rect = new fabric.Rect(
        {
          left: e.pointer!.x - canvasConstants.squareSize / 2,
          top: e.pointer!.y - canvasConstants.squareSize / 2,
          fill: 'transparent',
          strokeWidth: canvasConstants.strokeWidth,
          stroke: this.actualColor,
          width: canvasConstants.squareSize,
          height: canvasConstants.squareSize,
          name: this.userId.toString()
        });

      this.canvas!.add(rect);
      this.canvas!.off("mouse:up");
      this.objectModified();
    });
  }

  addText() {
    this.canvas!.isDrawingMode = false;

    this.canvas!.on("mouse:up", (e) => {
      var text = new fabric.IText(canvasConstants.defaultText, {
        width: 200,
        fontSize: 52,
        textAlign: 'center',
        fontFamily: 'Arial',
        top: e.pointer!.y,
        left: e.pointer!.x,
        fill: this.actualColor,
        name: this.userId.toString()
      });

      this.canvas!.add(text);
      this.canvas!.off("mouse:up");
      this.objectModified();
    });
  }

  public isFreeDrawing(): boolean{
    if (this.canvas == undefined)
      return false;

    return this.canvas!.isDrawingMode!;
  }

  freeDrawing(value:boolean) {
    this.canvas.isDrawingMode = value;
    this.canvas.freeDrawingBrush.width = canvasConstants.strokeWidth;
    this.canvas.freeDrawingBrush.color = this.actualColor;
    let that = this;
    this.canvas.on('path:created', function (e) {
      that.objectModified();
    });
  }

  stopFreeDrawing() {
    this.canvas!.isDrawingMode = false;
    this.canvas!.freeDrawingBrush.width = canvasConstants.strokeWidth;
    this.canvas!.freeDrawingBrush.color = this.actualColor;
    this.canvas!.off('path:created');
  }

  eraseAll() {
    //if (this.hasObjects()) {
      let objects = this.canvas?.getObjects();
      objects?.forEach((o) => { this.canvas?.remove(o) });
      let newobjects: ModifiedObjectsEmitterData = { canvasWidth: this.canvas?.width!, canvasHeigth: this.canvas?.height!, canvasObjects: this.canvas!.toJSON() };
      this.OnObjectModified.emit(newobjects);
    //}
  }

  hasObjects(): boolean {
    if (this.canvas?.getObjects())
      return true
    return false;
  }

  getImage(): string {
    return this.canvas!.toDataURL({
      format: 'jpg',
      quality: 0.8,
    })
  }

  generateGUID(): string {
    const timestamp = new Date().getTime();
    const randomNum = Math.floor(Math.random() * 1000000);
    return `${timestamp}-${randomNum}`;
  }

  clear() {
    if (this.hasObjects()) {
      this.canvas!.remove(...this.canvas!.getObjects());
    }
  }

  objectModified() {
    let canvasObjects = this.canvas!.getObjects();
    canvasObjects.forEach((obj) => {
      console.log("Actual object name: " + obj.name)
      if (obj.name == undefined || obj.name.length == 0) {
        obj.name = this.userId.toString();
      }
    });
    let signalRDrawObjects: SignalRDrawObject = this.canvas!.toJSON(["name"]);
    let emitData: ModifiedObjectsEmitterData = { canvasHeigth: this.canvas!.height!, canvasWidth: this.canvas!.width!, canvasObjects: signalRDrawObjects };
    this.OnObjectModified.emit(emitData);
  }

  liveObject(data: ModifiedObjectsEmitterData) {
    if (this.isCanvasReady()) {
      let that = this;

      let scaleY = this.canvas?.height! / data.canvasHeigth;
      let scaleX = this.canvas?.width! / data.canvasWidth;
      console.log("Scales :" + scaleX + "x" + scaleY);
      const bkImage = this.canvas!.backgroundImage;

      this.canvas!.loadFromJSON(data.canvasObjects, () => {
        try {
          that.canvas!.getObjects().forEach((obj) => {
            obj.scaleX =obj.scaleX! * scaleX;
            obj.scaleY =obj.scaleY! * scaleY;
            obj.left = obj.left! * scaleX;
            obj.top = obj.top! * scaleY;
            console.log("object name: " + obj.name)
            obj.selectable = obj.name === this.userId.toString() ? true : false;
          });
        }
        catch (e) {
          console.log("Error :" + e);
        }
        that.canvas!.backgroundImage = bkImage;
        that.canvas!.renderAll();
        // to try other types of renders in 7417 7418 
        //that.canvas!.renderTop();
      });

      console.log(" objects from canvas :" + JSON.stringify(that.canvas!.getObjects()));
    }
  }

  changeColor(value: string) {
    this.actualColor = value;
    this.canvas!.freeDrawingBrush.color = value;
  }


  destroy() {
    if (this.canvas) {
      this.canvas.dispose();
    }
  }

}
