import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { UserRole } from '../../../constants/constants';


@Component({
  selector: 'directCall-pointer',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './pointer.component.html',
  styleUrls: ['./pointer.component.scss']
})

export class PointerComponent {

  @Input() position!: {x: number, y: number} | null;
  @Input() role!: UserRole;

  userRole = UserRole;
  timer: any;

  ngOnChanges(): void {
    if (this.position) {
      console.log("set timer pointer", this.role);
      clearTimeout(this.timer);

      this.timer = setTimeout(() => {
        console.log("Time out!", this.role);
        this.position = null;
      }, 3000);
    }
  }
}
