import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MessageComponent } from './message/message.component';
import { chatMessage } from '../../models/chatMessage'
import { FormsModule } from '@angular/forms';
import { UserDataService } from 'src/app/services/userData.service';
import { SignalRService } from 'src/app/services/communications/signalr.service';
import { ApiService } from 'src/app/services/communications/api.service';
import { ChatService } from 'src/app/services/chat.service';
import { Subscription } from 'rxjs';
import { chatMessageResponse } from 'src/app/models/chatMessageResponse';
import { FileHelperService } from 'src/app/services/file-helper.service';
import { chatFile } from 'src/app/models/chatFile';
import { CameraComponent } from '../cameraComponent/cameraComponent';
import { MatDialog } from '@angular/material/dialog';
import { RecordMediaService } from 'src/app/services/recordMedia.service';



@Component({
  selector: 'directCall-chat',
  standalone: true,
  imports: [CommonModule, MessageComponent, FormsModule],
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss', '../../styles/components/panel.scss']
})

export class ChatComponent implements AfterViewInit, OnDestroy {

  @Input() stickyMode: boolean = false;
  @Output() close = new EventEmitter<string>();

  @ViewChild('messagesContainer') private messagesContainer!: ElementRef;
  @ViewChild('fileInput') fileInput!: ElementRef;
  @ViewChild('textarea') textarea!: ElementRef;

  public firstLoad: boolean = true;
  private mutationObserver!: MutationObserver;

  newMessage: chatMessage;
  group: chatMessage[][] = [];
  currentName: string;
  allowMembers = false;
  attachment: Blob | null = null;

  chatMessageReceivedSubscription: Subscription;
  chatReceivedSubscription: Subscription;
  isRecordingVoice = false;

  constructor(private userDataService: UserDataService, private signalService: SignalRService, private apiService: ApiService, private chatService: ChatService,
    private fileHelperService: FileHelperService, public dialog: MatDialog, private recordMediaService: RecordMediaService) {

    this.currentName = userDataService.Name != '' ? userDataService.Name! : 'GUEST';

    this.newMessage = new chatMessage(this.userDataService.Id!, '');
    this.newMessage.participantName = this.currentName;
    console.debug("Chat component constructor...");

    this.chatMessageReceivedSubscription = this.signalService.onChatMessageReceived.subscribe((data => {
      console.log("onChatMessageReceived...");
      this.addMessageResponse(data);
    }));

    this.chatReceivedSubscription = this.chatService.onChatReceived.subscribe((data) => {
      console.log("onChatReceived...");
      data.forEach((message) => {
        this.addMessageResponse(message)
      })
      this.firstLoad = true;
      this.group = this.groupMessagesByDate(this.chatService.messages);
    })

    this.recordMediaService.recordingFailed().subscribe(() => console.log("Record failed"));

    this.recordMediaService.getRecordedBlob().subscribe(data => {
      const file :File = new File([data.blob], data.title, { type: 'audio/webm' });
      this.newMessage.chatFile = new chatFile(-1, file.name, file.type, "", file);
    });
  }

  ngOnDestroy(): void {
    console.debug("Chat received destroy subscription");
    this.chatMessageReceivedSubscription.unsubscribe();
    this.chatReceivedSubscription.unsubscribe();
    if (this.mutationObserver) {
      this.mutationObserver.disconnect();
    }
  }

  ngAfterViewInit(): void {
    console.log("After view checked:" + JSON.stringify(this.chatService.messages))
    this.group = this.groupMessagesByDate(this.chatService.messages);
    this.initializeMutationObserver();
  }

  initializeMutationObserver(): void {
    this.mutationObserver = new MutationObserver(() => {
      this.scrollDown();
      this.firstLoad = false;
    });

    this.mutationObserver.observe(this.messagesContainer.nativeElement, {
      childList: true,
      subtree: true,
      characterData: true
    });
  }

  private scrollDown(): void {
    if (this.messagesContainer && this.messagesContainer.nativeElement) {
      try {
        this.messagesContainer.nativeElement.scrollTop = this.messagesContainer.nativeElement.scrollHeight;
        console.log("Scroll down");
      } catch (err) { }
    }

  }

  groupMessagesByDate(messages: chatMessage[]): chatMessage[][] {
    const groups: { [key: string]: chatMessage[] } = {};

    messages.forEach(message => {
      //también les asigno el color (preparado para cuando sean más de dos)
      message.color = this.userDataService.roomInfo.roomParticipants.find(p => p.id == message.userId)?.color;

      const dateKey = new Date(message.date).toDateString();
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey].push(message);
    });

    return Object.values(groups);
  }

  adjustTextareaHeight() {
    this.textarea.nativeElement.style.height = 'auto';

    const alturaDeseada = this.textarea.nativeElement.scrollHeight - 26;
    const alturaMaxima = 6 * 22; // Altura máxima de 6 líneas
    this.textarea.nativeElement.style.height = Math.min(alturaDeseada, alturaMaxima) + 'px';
  }

  resetTextareaHeight() {
    this.textarea.nativeElement.style.height = '34px'; //definido en el css
  }

  async addNewMessage(): Promise<void> {
    console.log("addnewmwssage attachment : ", this.attachment);

    if (this.newMessage.userId && (this.newMessage.message.trim() || this.newMessage.chatFile?.file)) {
      var user = this.userDataService.roomInfo.roomParticipants.find(p => p.id == this.userDataService.Id!);
      if (user == undefined) return;

      this.newMessage.date = new Date();
      this.newMessage.color = user.color;

      // if (this.attachment != null )
      //   this.newMessage.chatFile = new chatFile(-1, this.attachment.name, this.attachment.type, "", this.attachment);

      if (!user.translateChatOn) this.chatService.messages.push(this.newMessage);

      this.newMessage = new chatMessage(this.userDataService.Id!, this.newMessage.message, this.newMessage.date, this.newMessage.participantName, "", "", this.newMessage.chatFile);

      if (this.fileInput) {
        this.fileInput.nativeElement.value = '';
      }

      this.group = this.groupMessagesByDate(this.chatService.messages);

      console.log(" sending message : ", user.translateChatOn, user.translationLanguage, this.newMessage.chatFile);

      this.apiService.SendChatMessage(this.signalService.getConnectionId()!, this.userDataService.RoomId!, this.newMessage, user.translateChatOn, user.translationLanguage, this.newMessage.chatFile?.file ?? null, this.attachment ?? null).subscribe({
        next: data => {
          console.log("Send Chat message ok");
          this.scrollDown();
        },
        error: error => {
          console.log("Send chat message error:" + error);
        }
      });

      this.newMessage.message = "";
      this.newMessage.chatFile = null;
      this.resetTextareaHeight();

    } else console.log('No había participante, o mensaje o imagen');

  }

  recordVoice():void {
    if(this.isRecordingVoice) {
      this.isRecordingVoice = false;
      this.recordMediaService.stopRecordingAudio();
    } else {
      this.recordMediaService.startRecordingAudio();
      this.isRecordingVoice = true;
    }
  }

  onFileSelected(event: any) {
    const file: File = event.target.files[0];
    this.newMessage.chatFile = new chatFile(-1, file.name, file.type, "", file);
  }

  clearAttachment() {
    this.newMessage.chatFile = null;
    if (this.fileInput) {
      this.fileInput.nativeElement.value = '';
    }
  }

  addMessage(data: chatMessage) {
    console.info("chat message added");
    this.chatService.messages.push(data);
    this.group = this.groupMessagesByDate(this.chatService.messages);
  }

  addMessageResponse(chatMessageResponse: chatMessageResponse) {
    console.info("chat message added with addMessage Response");
    if (chatMessageResponse.chatFile != null && chatMessageResponse.chatFile?.fileId > 0) {
      console.log("chat message has file ", chatMessageResponse.chatFile.fileName);
      let file: File | null = null;
      console.log("chat file extension is ", this.fileHelperService.getFileExtension(chatMessageResponse.chatFile.fileName), this.fileHelperService.getFileType(chatMessageResponse.chatFile.fileName));

      console.log("requesting image file");

      if (chatMessageResponse.chatFile.mediaType.includes('video')) {
        console.log("Is video");
        this.apiService.requestVideoUrl(chatMessageResponse.chatFile.fileId, chatMessageResponse.chatFile.fileName, this.userDataService.Id!).subscribe({
          next: data => {
            console.log('url:', data.videoUrl);
            chatMessageResponse.chatData.chatFile = new chatFile(chatMessageResponse.chatFile?.fileId!, chatMessageResponse.chatFile?.fileName!, chatMessageResponse.chatFile?.mediaType!, data.videoUrl, data.videoUrl);
            console.log(chatMessageResponse);
          },
          error: err => {
            console.error('Error getting video url :', err);
          }
        });
      } else if (chatMessageResponse.chatFile.mediaType.includes('audio')) {
        console.log("Is audio");
        this.apiService.requestAudioUrl(chatMessageResponse.chatFile.fileId, chatMessageResponse.chatFile.fileName, this.userDataService.Id!).subscribe({
          next: data => {
            console.log('url:', data.audioUrl);
            chatMessageResponse.chatData.chatFile = new chatFile(chatMessageResponse.chatFile?.fileId!, chatMessageResponse.chatFile?.fileName!, chatMessageResponse.chatFile?.mediaType!, data.audioUrl, data.audioUrl);
            console.log(chatMessageResponse);
          },
          error: err => {
            console.error('Error getting audio url :', err);
          }
        });
      

      } else {
        this.apiService.requestChatFile(chatMessageResponse.chatFile.fileId, chatMessageResponse.chatFile.fileName, this.userDataService.Id!).subscribe({
          next: data => {
            let file: File = new File([data], chatMessageResponse.chatFile?.fileName!, { type: chatMessageResponse.chatFile?.mediaType })
            console.log('Downloaded file:', file);
            console.log('file mediatype:', file.type);
            chatMessageResponse.chatData.chatFile = new chatFile(chatMessageResponse.chatFile?.fileId!, chatMessageResponse.chatFile?.fileName!, chatMessageResponse.chatFile?.mediaType!, "", file);

          },
          error: err => {
            console.error('Error downloading file:', err);
          }
        });
      }

    }
    console.log(chatMessageResponse.chatData);
    this.chatService.messages.push(chatMessageResponse.chatData);
    this.group = this.groupMessagesByDate(this.chatService.messages);
    this.scrollDown();
  }

  closeChat() {
    this.close.emit();
  }

  takePhoto() {
    console.log("take photo");
    let dialogRef: any;
    dialogRef = this.dialog.open(CameraComponent, {
      disableClose: true
    });

    dialogRef!.afterClosed().subscribe((result: File) => {
      if (result !== null) {
        console.log("result file", result);
        this.newMessage.chatFile = new chatFile(-1, result.name, result.type, "", result);
      }
      dialogRef = null;
    });
  }

}

