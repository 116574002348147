import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-loader',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})

export class LoaderComponent {

  token: string | null = null;
  @Input() loaderMode: boolean = false;
  @Input() answer: string = '';
  constructor(private activateRoute: ActivatedRoute, private loginService: LoginService, private router: Router, private translate: TranslateService,) {
  }

  ngOnInit(): void {

    if (!this.loaderMode) { //Esto sólo lo hace en el inicio

      this.token = this.activateRoute.snapshot.queryParams['token'];
      console.log(this.token);
      if (this.token == null)
        this.translate.get("FORCED_LEAVE").subscribe((text: string) => {
          this.answer = text;
        });
      else
        this.loginService.loginToken(this.token).subscribe({
          next: result => {
            this.answer = "TOKEN.TOKEN_OK";
            this.router.navigate(['waitingroom']);
            console.log('Todo ok:', result);
          },
          error: errorMessage => {
            this.answer = errorMessage;// "TOKEN.TOKEN_NOT_OK";
          }
        });
    }

  }

}
