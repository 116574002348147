<div class="directCallMembers panel" [class.stickyMode]="stickyMode" [class.withMenu]="withMenu">
  <div *ngIf="!stickyMode" class="close" (click)="closeChat()">
    <label class="close-icon">
      <i class="material-icons">close</i>
    </label>
  </div>
  <div *ngIf="this.userDataService.roomInfo" class="allparticipants">
    <h3 class="participants-title">{{'PARTICIPANTS' | translate}}</h3>
    <div *ngFor="let participant of this.userDataService.roomInfo.roomParticipants" class="participant">
      <div class="visible-info">
        <div class="icon" id="participantIcon"> <img [src]=getBackgroundImage(participant) /></div>
        <div *ngIf="participant.name" class="name">
          <p>{{participant.name}}</p>
        </div>
        <div *ngIf="!participant.name" class="name">
          <p>{{'GUEST' | translate}}</p>
        </div>
        <!--<div *ngIf="versionShow" class="mute" (click)="switchMute(participant)">
          <img *ngIf="!participant.mute" src="/assets/images/buttons/voice-on.svg" />
          <img *ngIf="participant.mute" src="/assets/images/buttons/voice-off.svg" />
        </div>-->
        <div *ngIf="isExpert" class="translations">
          <img *ngIf="participant.isInRoom" src="/assets/images/buttons/translation.svg" (click)="toggleTranslations(participant)" />
          <img *ngIf="!participant.isInRoom" src="/assets/images/buttons/translation-bn.svg" />
        </div>

      </div>
      <div *ngIf="isExpert" class="translations-info" [ngClass]="{ 'translations-open': participant.translationsOpen }">
        <div class="origin-language">
          <mat-form-field class="translateselect">
            <mat-select #langOriginSelect [(value)]="participant.originLanguage" placeholder="{{'LANGUAGE' | translate}}"
                        [disabled]="participant.translateVoiceOn != undefined && participant.translateVoiceOn && participant.originLanguage != undefined && participant.translationLanguage != undefined" (selectionChange)="updateTranslationLang(participant, true)">
              <mat-option [value]="undefined"> ----- </mat-option>
              <mat-option *ngFor="let lang of langsFrom" [value]="lang[0]">{{lang[1]}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="translated-language">
          <mat-form-field class="translateselect">
            <mat-select #langEndSelect [(value)]="participant.translationLanguage" placeholder="{{'LANGUAGE' | translate}}"
                        [disabled]="participant.translateVoiceOn != undefined && participant.translateVoiceOn && participant.originLanguage != undefined && participant.translationLanguage != undefined" (selectionChange)="updateTranslationLang(participant, false)">
              <mat-option [value]="undefined"> ----- </mat-option>
              <mat-option *ngFor="let lang of langs" [value]="lang[0]">{{lang[1]}}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="translate-button" id="toggle_publisher_video_type_div">
          <button *ngIf="participant.translateVoiceOn && isExpert" (click)="translationVoiceEnabled ? translateVoice(participant) : null" class="btn btn-default sidebar">
            <img src="/assets/images/translations/translation-stop.svg" class="call-button hvr-grow" style="padding: 3px 0">
          </button>
          <button *ngIf="!participant.translateVoiceOn && isExpert" class="btn btn-default sidebar"
                  [disabled]="!participant.microphone || !translationVoiceEnabled || participant.originLanguage == undefined || participant.translationLanguage == undefined"
                  (click)="translationVoiceEnabled && (participant.originLanguage != undefined && participant.translationLanguage != undefined) ? translateVoice(participant) : null">
            <img src="/assets/images/translations/translation-start.svg" class="call-button hvr-grow" style="padding: 3px 0">
          </button>
        </div>
      </div>

    </div>
  </div>

  <!-- SUBTITLES -->
  <div *ngIf="isExpert" class="subtitles-on check" [class.selected]="translateSubtitlesOn" [class.disabled-checkbox]="!translateSubtitleEnabled">
    <img [src]="translateSubtitlesOn ? '/assets/images/translations/translation-subtitle.svg' : '/assets/images/translations/translation-subtitle-off.svg'" />
    <p>{{'TRANSLATION.SUBTITLES' | translate}}</p>
    <mat-slide-toggle color="primary" hideIcon="true" (toggleChange)="audioConnectorService.translateSubtitles()" [disabled]="!translateSubtitleEnabled" [checked]="translateSubtitlesOn" class="material-icons"></mat-slide-toggle>
  </div>

  <!-- CHAT -->
  <div *ngIf="isExpert" class="translate-chat-on check" [class.selected]="translateChatOn" [class.disabled-checkbox]="!chatEnabled">
    <img [src]="translateChatOn ? '/assets/images/translations/translation.svg' : '/assets/images/translations/translation-off.svg'" />
    <p style="color:white">{{'TRANSLATION.CHAT' | translate}}</p>
    <mat-slide-toggle color="primary" hideIcon="true" (toggleChange)="translateChat()" [disabled]="!chatEnabled" [checked]="translateChatOn" class="material-icons"></mat-slide-toggle>
  </div>

  <!-- TRANSCRIPTIONS -->
  <div *ngIf="isExpert" class="transcriptions-on check" [class.selected]="transcriptionsOn" [class.disabled-checkbox]="!transcriptionsEnabled">
    <img [src]="transcriptionsOn ? '/assets/images/translations/translation.svg' : '/assets/images/translations/translation-off.svg'" />
    <p style="color:white">{{'TRANSCRIPTIONS' | translate}}</p>
    <mat-slide-toggle color="primary" hideIcon="true" (toggleChange)="this.toggleTranscriptions()" [disabled]="!transcriptionsEnabled" [checked]="transcriptionsOn" class="material-icons"></mat-slide-toggle>
  </div>
