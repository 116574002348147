<div class="message" [class.me]="chatMessage.userId == currentUserId">
    <div *ngIf="chatMessage.participantName" class="participant" [style.color]="color">
        <p>{{chatMessage.participantName | translate}}</p>
    </div>
    <div *ngIf="!chatMessage.participantName" class="participant" [style.color]="color">
        <p>{{'GUEST' | translate}}</p>
    </div>
    <!-- Se podrá quitar el if y la segunda línea cuando todos tengan nombre -->
    <div class="globe" [style.background]="backgroundColor" [style.border]="borderColor">

      <div *ngIf="chatMessage.chatFile?.file">
          <div *ngIf="fileHelperService.isUrl(chatMessage.chatFile?.file)">
              <img *ngIf="chatMessage.chatFile?.mediaType?.startsWith('image')" [src]="chatMessage.chatFile?.file"  (click)="openMediaModal(chatMessage.chatFile?.file!)">
              <video *ngIf="chatMessage.chatFile?.mediaType?.startsWith('video')" [src]="chatMessage.chatFile?.file" controls></video>
              <audio *ngIf="chatMessage.chatFile?.mediaType?.startsWith('audio')" [src]="chatMessage.chatFile?.file" controls></audio>
              <!--<img *ngIf="chatMessage.chatFile?.file" [src]="chatMessage.chatFile?.file" (click)="openMediaModal(chatMessage.chatFile?.file!)">-->
          </div>

          <div *ngIf="!fileHelperService.isUrl(chatMessage.chatFile?.file)" (click)="openMediaModal(chatMessage.chatFile!.file!)">
              <img *ngIf="fileHelperService.isFileImage(chatMessage.chatFile?.file!)" [src]="fileHelperService.convertFileToUrl(chatMessage.chatFile?.file!)" (click)="openMediaModal(chatMessage.chatFile?.file!)">
              <video *ngIf="fileHelperService.isFileVideo(chatMessage.chatFile?.file!)" [src]="fileHelperService.convertFileToUrl(chatMessage.chatFile?.file!)" controls></video>
			  <audio *ngIf="fileHelperService.isFileAudio(chatMessage.chatFile?.file!)" [src]="fileHelperService.convertFileToUrl(chatMessage.chatFile?.file!)" controls></audio>
          </div>
          <img *ngIf="chatMessage.chatFile?.mediaType?.includes('pdf')" src="/assets/images/filetypes/pdf_60x74.png"  (click)="downloadFile()">
      </div>

      <!--<img *ngIf="chatMessage.chatFile?.url?.length! > 0" src="/assets/images/filetypes/video-icon_60x54.png" (click)="openVideoUrl()">-->

      <div class="text">
          <p *ngIf="chatMessage.message" [innerHTML]="chatMessage.message | linkify | escapeHtml"></p>
      </div>

      <div class="text" *ngIf="chatMessage.message && chatMessage.translation">
          <p class="translate"><i [innerHTML]="chatMessage.translation | linkify | escapeHtml"></i></p>
      </div>

      <div class="date">
          <p> {{ chatMessage.date | date:'hh:mm' }}</p>
      </div>
    </div>
</div>
