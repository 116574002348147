export enum UserRole {
  Expert,
  Technician,
  Guest
}

export enum PublisherType {
  Video = 0,
  ShareScreen = 1,
  AudioTranslate = 2
}

export enum StreamVideoType {
  Camera = "camera", // a standard video stream that uses a camera as the video source
  Screen = "screen", // a screen sharing video stream
  Custom = "custom", // a stream published by a web client using an HTML VideoTrack element as the video source
  Undefined = "undefined" // a stream is voice-only
}

export enum NavBars {
    BASIC = 'BASIC',
    RESOLUTION = 'RESOLUTION',
    EDITCAPTURE = 'EDITCAPTURE'
}

export class ApiUrls {
    public static lastActions = "/configuration/lastactions";
    public static loginToken = '/Authenticate/logintoken';
    public static roomInformation = '/Rooms/getroominfo';
    public static setConnectionId = '/Calls/UpdateConnectionId';
    public static hangUp = '/Calls/HangUp';
    public static startCall = '/Calls/StartCall';
    public static signalRHub = '/DirectCallHub';
    public static generalInfo = "/ClientError/GeneralInfo";
    public static error = "/ClientError/Error";
    public static sendImage = "/Media/SendImage";
    public static startLiveDrawing = "/Media/StartLiveDrawing";
    public static updateTags = "/Calls/UpdateTags";
    public static updateVideoTags = "/Media/UpdateVideoTags";
    public static startVideoRecording = "/Media/StartVideoRecording";
    public static stopVideoRecording = "/Media/StopVideoRecording";
    public static requestHDImage = "/Media/RequestHDImage";
    public static sendHdImage = "/Media/SendHdImage";
    public static sendChatMessage = "/Chat/SendChatMessage";
    public static getChatMessages = "/Chat/GetChat";
    public static getAudioTranslated = "/Media/GetAudioTranslated";
    public static updateToken = '/Authenticate/GetUpdatedToken';
    public static callNotification = '/Calls/CallNotification';
    public static callNotificationResponse = '/Calls/CallNotificationResponse';
    public static activityLogsAdd = '/ActivityLogs/Add';
}

export const OnVideoOpacity = 1;
export const NoVideoOpacityTechnician = 0.3;
export const NoVideoOpacityExpert = 0;
export const Result_Ok = 'ok';
export const Result_Ko = 'ko';


export enum ImageEditorActions {
    Close,
    saveAndClose,
    saveAndSend,
    RemoteClose
}

export const snackBarDurationInSeconds = 3;

export enum ImageEditorModes {
    Edit,
    ImageReceived,
    LiveDraw
}
