import { EventEmitter, Injectable } from '@angular/core';
import { UserDataService } from './userData.service';
import { ApiService } from './communications/api.service';
import { chatMessage } from '../models/chatMessage';

@Injectable({
  providedIn: 'root'
})
export class ChatService {

  messages: chatMessage[] = [];
  translate: boolean = false;
  translateLanguage: string = "";
  chatColors = ["#8c8c8c", "#64b4b4", "#f66a57", "#92c746", "#46C7BA", "#f09f29", "#9c27b0", "#3751b5", "#dfe749"];
  chatBackgroundColors = ["#ffffff", "#46c7ba", "#f66a57", "#92c746", "#46C7BA", "#f09f29", "#9c27b0", "#3751b5", "#dfe749"];

  onChatReceived = new EventEmitter<void>();

  constructor(private userDataService: UserDataService, private apiService: ApiService) {
    console.debug("Chat service constructor, update chat");
    this.updateChat();
  }

  assignColorsToParticipants(userId: number): void {
    if (!this.userDataService.roomInfo) return;
      
    for (let i = 0; i < this.userDataService.roomInfo.roomParticipants.length; i++) {
      if (this.userDataService.roomInfo.roomParticipants[i].id != userId) { 
        console.log("COLOR", i, this.userDataService.roomInfo.roomParticipants[i].id, this.chatColors[i % this.chatColors.length]);
        var index = i % this.chatColors.length;
        this.userDataService.roomInfo.roomParticipants[i].color = this.chatColors[(index == 0 ? index + 1 : index)];
      } else {
        this.userDataService.roomInfo.roomParticipants[i].color = this.chatColors[0]; //currentUser siempre se pinta blanco
      }
    }
    
  }

  getBackgroundColor(color: string) {
    var index = this.chatColors.findIndex(c => c == color);
    return this.chatBackgroundColors[index];
  }

  updateChat() {
    this.apiService.GetChatForRoom(this.userDataService.RoomId!, this.userDataService.Id!).subscribe({
      next: (data) => {
        this.messages = data;
        this.onChatReceived.emit();
      },
      error: (error) => { console.error(error); }
    });
  }

}


