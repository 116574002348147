<div *ngIf="!isExpert" class="animation" [ngClass]="{'blinking': recording}">
  <div [ngClass]="{'blinking-icon': recording}" class="recording-indicator">
    <!-- Icono parpadeante
    <mat-icon [ngClass]="{'recording-icon': true}" class="recording-icon">videocam</mat-icon>-->
    <span class="material-icons md-light">videocam</span>
  </div>
</div>
<div class="background"></div>

<div *ngIf="session" #publisherContainer>
  <app-publisher [session]="session" [isExpert]="isExpert" [publisherType]="publisherType.Video"></app-publisher>
  <app-publisher style="display: none" [session]="session" [isExpert]="isExpert" [publisherType]="publisherType.ShareScreen" (onScreenShareStop)="stopScreenSharing()"></app-publisher> <!--#publisherShareScreen-->
  <app-publisher style="display: none" [session]="session" [isExpert]="isExpert" [publisherType]="publisherType.AudioTranslate"></app-publisher>
</div>

<div *ngIf="session">
  <app-subscriber *ngFor="let stream of streams" [stream]="stream" [session]="session" [isExpert]="isExpert"></app-subscriber>
</div>


<div class="content" #videoContainer (click)="showPointer($event)">
  <div class="end-call-button-area">
    <div class="end-call-button" (click)="endCall($event)"></div>
  </div>

  <div class="main">
    <div *ngIf="subtitle != ''" class="traducciones">
      <p>{{ subtitle }}</p>
    </div>
  </div>

  <div *ngIf="currentnavBar == navBars.BASIC" class="buttons-area" #container>
    <div class="round-button silence-button" (click)="switchMicrophone($event)" #button>
      <img *ngIf="microActive" src="/assets/images/buttons/voice-on.svg" alt="Icono">
      <img *ngIf="!microActive" src="/assets/images/buttons/voice-off.svg" alt="Icono">
    </div>
    <div *ngIf="hasFlash" class="round-button flash-button" (click)="switchFlash($event)" #button>
      <img *ngIf="!flashOn" src="/assets/images/buttons/flash-off.svg" alt="Icono">
      <img *ngIf="flashOn" src="/assets/images/buttons/flash-on.svg" alt="Icono">
    </div>
    <div class="round-button members-button" (click)="switchSection('members', $event)" #button>
      <img src="/assets/images/buttons/contacts.svg" alt="Icono">
    </div>
    <div class="round-button chat-button" (click)="switchSection('chat', $event)" #button>
      <img src="/assets/images/buttons/chat.svg" alt="Icono">
    </div>
    <div *ngIf="isExpert" class="round-button capture-button" (click)="captureAndEdit($event)" #button>
      <img src="/assets/images/buttons/capture.svg" alt="Icono">
    </div>
    <div *ngIf="isExpert" class="round-button capture-button" (click)="requestHDCapture($event)" #button>
      <img src="/assets/images/buttons/hd-screen-capture.svg" alt="Icono">
    </div>
    <div *ngIf="isExpert" class="round-button record-button" #button>
      <img *ngIf="!recording" src="/assets/images/buttons/record.svg" alt="Icono" (click)="startRecording($event)">
      <img *ngIf="recording" src="/assets/images/buttons/stop.svg" alt="Icono" (click)="stopRecording($event)">
      <div *ngIf="recording" class="recording" (click)="stopRecording($event)">
        <img src="/assets/images/buttons/stop.svg" alt="Icono">
        <div class="time">{{ getRecordingTime() }}</div>
      </div>
    </div>
    <div *ngIf="screenShareAvailable" [ngClass]="{'disabled': screenShareDisabled }" class="round-button screen-share-button"
         (click)="switchScreenSharing($event)" #button>
      <img *ngIf="!screenShareOn" src="/assets/images/buttons/screen-share.svg" alt="Icono">
      <img *ngIf="screenShareOn" src="/assets/images/buttons/stop-sharing.svg" alt="Icono">
    </div>
    <div *ngIf="isExpert" class="round-button tags-button" (click)="openTagsModal($event)" #button>
      <img *ngIf="roomInfo && !roomInfo.tags[0]" src="/assets/images/buttons/tags.svg" alt="tags">
      <img *ngIf="roomInfo && roomInfo.tags[0]" src="/assets/images/buttons/tags-with-data.svg" alt="tags">
    </div>
    <div *ngIf="hasResolution" class="round-button resolution-button" (click)="changeButtonsTo(navBars.RESOLUTION, $event)" #button>
      <img *ngIf="resolution == 1080" src="/assets/images/buttons/resolution-1080.svg" alt="Icono">
      <img *ngIf="resolution == 720" src="/assets/images/buttons/resolution-720.svg" alt="Icono">
      <img *ngIf="resolution == 480" src="/assets/images/buttons/resolution-480.svg" alt="Icono">
      <img *ngIf="resolution == 240" src="/assets/images/buttons/resolution-240.svg" alt="Icono">
    </div>
    <div *ngIf="!isExpert" class="round-button change-camera-button" (click)="switchCamera($event)" #button>
      <img src="/assets/images/buttons/change-camera.svg" alt="Icono">
    </div>
    <div class="round-button full-screen-button" (click)="switchFullScreen($event)" #button>
      <img *ngIf="!fullScreen" src="/assets/images/buttons/full-screen.svg" alt="Icono">
      <img *ngIf="fullScreen" src="/assets/images/buttons/regular-screen.svg" alt="Icono">
    </div>
  </div>
  <div *ngIf="currentnavBar == navBars.RESOLUTION" class="buttons-area">
    <div class="round-button close-button" (click)="changeButtonsTo(navBars.BASIC, $event)">
      <img src="/assets/images/buttons/close.svg" alt="Icono">
    </div>
    <div class="round-button resolution-button" (click)="setResolution(1080, $event)">
      <img src="/assets/images/buttons/resolution-1080.svg" alt="Icono">
    </div>
    <div class="round-button resolution-button" (click)="setResolution(720, $event)">
      <img src="/assets/images/buttons/resolution-720.svg" alt="Icono">
    </div>
    <div class="round-button resolution-button" (click)="setResolution(480, $event)">
      <img src="/assets/images/buttons/resolution-480.svg" alt="Icono">
    </div>
    <div class="round-button resolution-button" (click)="setResolution(240, $event)">
      <img src="/assets/images/buttons/resolution-240.svg" alt="Icono">
    </div>
  </div>
</div>
<directCall-chat *ngIf="chatOn" (close)="switchSection('chat', $event)"></directCall-chat>
<directCall-members [class.membersOff]="!membersOn" (close)="switchSection('members', $event)"></directCall-members>
<directCall-pointer *ngFor="let roomParticipant of roomInfo?.roomParticipants" [position]="roomParticipant.pointer" [role]="roomParticipant.role"></directCall-pointer>
