import { Injectable } from '@angular/core';
import { environment } from 'src/environment/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiUrls, UserRole } from '../../constants/constants';
import { AuthToketRequest } from '../../interfaces/Requests/AuthTokenRequest';
import { AuthTokenResponse } from '../../interfaces/Responses/AuthTokenResponse';
import { RoomInfo } from '../../interfaces/RoomInfo';
import { StartCallResponse } from '../../interfaces/Responses/StartCallResponse';
import { CallInfoResponse } from '../../interfaces/Responses/CallInfoResponse';
import { chatMessage, chatMessageRequest } from 'src/app/models/chatMessage';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  constructor(private httpClient: HttpClient) { }

  loginToken(token: string) {

    var request = new AuthToketRequest()
    request.remoteEyeTokenLink = token;

    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');

    return this.httpClient.post<AuthTokenResponse>(environment.apiUrl + ApiUrls.loginToken, request, { headers });
  }

  getRoomInfo(roomId: number, userId: number) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "RoomId": roomId, "UserId": userId };
    return this.httpClient.post<RoomInfo>(environment.apiUrl + ApiUrls.roomInformation, request, { headers });
  }

  registerConnectionId(callId: number | null, connectionId: string | undefined, roomId: number, userId: number | null) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "CallId": callId, "ConnectionId": connectionId, "RoomId": roomId, "UserId": userId };
    return this.httpClient.post(environment.apiUrl + ApiUrls.setConnectionId, request, { headers });
  }

  hangUp(roomId: number, userId: number, callId: number | null) {
    console.log("Sending Hang up for room : " + roomId);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "RoomId": roomId, "UserId": userId, "CallId": callId };
    return this.httpClient.post<CallInfoResponse>(environment.apiUrl + ApiUrls.hangUp, request, { headers });
  }

  startCall(roomId: number, userId: number, roleId: UserRole) {
    console.log("Sending start call  for room : " + roomId + " by :" + userId);
    var request = { "RoomId": roomId, "CallerId": userId, "CallerRole": roleId };
    return this.httpClient.post<StartCallResponse>(environment.apiUrl + ApiUrls.startCall, request);
  }

  sendGeneralInfo(message: string, info: string) {
    console.log("Sending general information " + ApiUrls.generalInfo);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "JsonString": info, "Message": message };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.generalInfo, request, { headers });
  }

  sendError(info: string) {
    console.log("Sending error " + ApiUrls.error);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { "JsonString": info };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.error, request, { headers });
  }

  updateTags(callId: number | null, tags: string[], userId: number) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { 'CallId': callId, 'Tags': tags.join(','), 'UserId': userId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateTags, request, { headers });
  }

  updateVideoTags(videoId: number | undefined, tags: string[]) {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');
    var request = { 'VideoId': videoId, 'Tags': tags.join(',') };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateVideoTags, request, { headers });
  }

  sendImage(callId: number, base64Img: string, userId: number, send: boolean, signalRConnectionId: string, roomId: number, IsLiveDraw: boolean, isHdImg: boolean) {
    console.log("Sending image " + ApiUrls.sendImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "Send": send, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId, "IsLiveDraw": IsLiveDraw, "IsHdImg": isHdImg };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendImage, request);
  }

  startLiveDrawing(callId: number, base64Img: string, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending image " + ApiUrls.sendImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.startLiveDrawing, request);
  }

  startVideoRecording(callId: number, base64Image: string, sessionId: string | null, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending start video recording " + ApiUrls.startVideoRecording);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');

    var request = { "CallId": callId, "Base64Image": base64Image, "SessionId": sessionId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.startVideoRecording, request, { headers });
  }

  stopVideoRecording(videoId: number | undefined, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("Sending stop video recording " + ApiUrls.stopVideoRecording);
    var request = { "VideoId": videoId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.stopVideoRecording, request);
  }

  requestHDImage(callId: number, userId: number, signalRConnectionId: string, roomId: number) {
    console.log("request hd image " + ApiUrls.requestHDImage);
    var request = { "CallId": callId, "UserId": userId, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.requestHDImage, request);
  }

  sendHdImage(callId: number, base64Img: string, userId: number, send: boolean, signalRConnectionId: string, roomId: number, senderSRId: string) {
    console.log("Sending HD image " + ApiUrls.sendHdImage);
    var request = { "CallId": callId, "Base64Image": base64Img, "UserId": userId, "Send": send, "SignalrConnectionId": signalRConnectionId, "RoomId": roomId, senderSignalRId: senderSRId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendHdImage, request);
  }

  SendChatMessage(signalRConnectionId: string, roomId: number, newMessage: chatMessage, translate: boolean, translateLanguage: string) {
    console.log("Sending Chat Message " + ApiUrls.sendChatMessage);
    let messageRequest: chatMessageRequest = { "FromUserId": newMessage.userId, "RoomId": roomId, "ChatMessage": newMessage, "SignalRConnectionId": signalRConnectionId, "Translate": translate, "TranslateLanguage": translateLanguage };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.sendChatMessage, messageRequest);
  }

  GetChatForRoom(roomId: number, userId: number) {
    console.log("get Chat Messages " + ApiUrls.getChatMessages);
    let request = { UserId: userId, RoomId: roomId };
    return this.httpClient.post<chatMessage[]>(environment.apiUrl + ApiUrls.getChatMessages, request);
  }

  getAudioFile(filePath: string) {
    console.log("Sending getAudioTranslated", ApiUrls.getAudioTranslated);
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/json; charset=utf-8');

    var request = { "FilePath": filePath };
    return this.httpClient.post<ArrayBuffer>(environment.apiUrl + ApiUrls.getAudioTranslated, request, { headers, responseType: 'arraybuffer' as 'json' });
  }

  getUpdatedToken(userId: number, roomId: number) {
    var request = { "RoomId": roomId, "UserId": userId };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.updateToken, request);
  }

  callNotification(userId: number, roomId: number, signalRConnectionId: string, active: boolean) {
    var request = { "RoomId": roomId, "CallerId": userId, "SignalRId": signalRConnectionId, "Active": active };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.callNotification, request);
  }

  callNotificationResponse(roomId: number, signalRConnectionId: string, accepted: boolean) {
    var request = { "RoomId": roomId, "SignalRId": signalRConnectionId, "Accepted": accepted };
    return this.httpClient.post<any>(environment.apiUrl + ApiUrls.callNotificationResponse, request);
  }

  registerEvent(eventTag: string, name: string, message: string, userId: number) {
    var request = { "EventTag": eventTag, "Name": name, "Message": message, "UserId": userId };
    this.httpClient.post<any>(environment.apiUrl + ApiUrls.activityLogsAdd, request).subscribe();
  }
}
